import {forkJoin, map, Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {MonCompteService} from '@app/mon-compte/mon-compte.service';

@Injectable()
export class InfosComplementairesVisiteurResolver  {

    constructor(private monCompteService: MonCompteService) {}

    resolve(): Observable<any> {
        return forkJoin([
            this.monCompteService.getUtilisateurGP().pipe(catchError(() => of([]))),
            this.monCompteService.getIndicatifTelephone().pipe(catchError(() => of([]))),
        ]).pipe(map(result => {
            return {
                utilisateurGP: result[0],
                listeIndicatif: result[1],
            };
        }));
    }
}
