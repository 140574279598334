import {ConnexionService} from '@app/connexion/connexion.service';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthentificationService} from '../authentification.service';
import {Data} from '@shared/util/data.model';
import {take} from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'ned-authentification-after',
    templateUrl: 'authentification-after.component.html',
    providers: [],
    standalone: true,
    imports: [TranslateModule],
})

/**
 * Component pour la gestion de l'authentification après l'authentification à FranceConnect
 * Va permettre de poursuivre le processus d'authentification et de récupération des informations
 */
export class AuthentificationAfterComponent implements OnInit {
    code = '';
    state = '';
    retour = '';

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authentificationService: AuthentificationService,
        private data: Data,
        private connexionService: ConnexionService,
    ) {}

    ngOnInit() {

        this.route.queryParams.subscribe(params => {
            this.code = params['code'];
            this.state = params['state'];
            this.retour = params['retour'];
            if (this.code && this.state) {
                this.authentificationService.authentificationFc(this.code, this.state, this.retour)
                    .subscribe({
                        next: () => {
                            this.connexionService.verifierPremiereConnexion()
                                .pipe(take(1))
                                .subscribe(response => {
                                    if (response.premiereConnexion) {
                                        this.router.navigate(['/connexion']);
                                    } else {
                                        this.router.navigate(['/' + this.retour]);
                                    }
                                });
                        },
                        error: response => {
                            if (response?.error?.message) {
                                this.data.storage = response.error.message;
                            }
                            this.router.navigate(['/authentification']);
                        },
                    });
            }
        });
    }
}
