import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigProvider} from '@app/blocks/config/config.provider';
import {DemandeSupport} from './models/demandeSupport.model';
import {UtilisateurGP} from '@app/mon-compte/models/utilisateurGP.model';

@Injectable({providedIn: 'root'})
export class ContactService {
    urlUtilisateurGPCourant = 'nedmasutilisateurfamille/api/v1/utilisateur-courant';
    public urls = {
        demandeSupport: this.configProvider.config.apiUrl
            + 'nedmascommunicationfamille/api/v1/demandesupport',
    };

    constructor(protected http: HttpClient, private configProvider: ConfigProvider) {
    }

    createDemandeSupport(demandeSupport: DemandeSupport) {
        return this.http.post(this.urls.demandeSupport, demandeSupport, {observe: 'response'});
    }

    getUtilisateurGP() {
        const url = this.configProvider.config.apiUrl + this.urlUtilisateurGPCourant;
        return this.http.get<UtilisateurGP>(url);
    }
}
