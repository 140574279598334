import {UtilisateurGP} from './models/utilisateurGP.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigProvider} from '../blocks/config/config.provider';
import {ReferentielTelephone} from './models/ReferentielTelephone.model';

@Injectable({providedIn: 'root'})
export class MonCompteService {
    urlUtilisateurGPCourant = 'nedmasutilisateurfamille/api/v1/utilisateur-courant';
    urlTelephone = 'nedmasreferentiel/api/indicatifs-telephones';

    constructor(private http: HttpClient, private configProvider: ConfigProvider) {}

    getUtilisateurGP() {
        const url = this.configProvider.config.apiUrl + this.urlUtilisateurGPCourant;
        return this.http.get<UtilisateurGP>(url);
    }

    getIndicatifTelephone() {
        const url = this.configProvider.config.apiUrl + this.urlTelephone;
        return this.http.get<ReferentielTelephone[]>(url);
    }

    getIndicatifTelephoneById(id: number) {
        const url = this.configProvider.config.apiUrl + this.urlTelephone + '/' + id;
        return this.http.get<ReferentielTelephone>(url);
    }

    updateUtilisateur(utilisateurGP: UtilisateurGP) {
        return this.http.put(this.configProvider.config.apiUrl + this.urlUtilisateurGPCourant, utilisateurGP);
    }

    suppressionCompte() {
        return this.http.delete(this.configProvider.config.apiUrl + 'nedmasutilisateurfamille/api/v1/utilisateur-gps');
    }
}
