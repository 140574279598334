import {AlertService} from '@shared/service/alert.service';
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {Principal} from '../auth/principal.service';
import {HEADER_X_XSRF_TOKEN} from '@app/blocks/interceptor/auth.interceptor';
import {take} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class UserRouteAccessService  {
    constructor(
        private router: Router,
        private principal: Principal,
        private alertService: AlertService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        // nettoyer les erreurs au changement de route
        this.alertService.clear();

        this.principal.identity().pipe(take(1)).subscribe(account => {
            if (account) {
                if (account.premiereConnexion) {
                    this.router.navigate(['/connexion']).then(() => window.location.reload());
                    return false;
                }
                return true;
            }
            return false;
        });

        if (sessionStorage.getItem(HEADER_X_XSRF_TOKEN)) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/authentification'], {queryParams: {returnUrl: state.url}});
        return false;
    }

    /*
        checkLogin(authorities: string[], url: string): Promise<boolean> {
            const principal = this.principal;
            return Promise.resolve(
                this.loginService
                    .login()
                    .then(jwt => {
                        if (!authorities || authorities.length === 0) {
                            return true;
                        }

                        if (jwt) {
                            return principal.hasAnyAuthority(authorities).then(response => {
                                if (response) {
                                    return true;
                                }
                                return false;
                            });
                        }

                        this.stateStorageService.storeUrl(url);
                        this.router.navigate(['accessdenied']);
                        return false;
                    })
                    .catch(err => {
                        this.stateStorageService.storeUrl(url);
                        this.router.navigate(['accessdenied']);
                        return false;
                    }),
            );
        }
    */
}
