import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'ned-error',
    templateUrl: './error.component.html',
    standalone: true,
    imports: [TranslateModule],
})
export class ErrorComponent implements OnInit {
    errorMessage = '';
    error403 = false;

    constructor(private route: ActivatedRoute) {}

    ngOnInit() {
        this.route.data.subscribe(routeData => {
            if (routeData['error403']) {
                this.error403 = routeData['error403'];
            }
            if (routeData['errorMessage']) {
                this.errorMessage = routeData['errorMessage'];
            }
        });
    }
}
