import {Pipe, PipeTransform} from '@angular/core';
import {locale} from 'moment';
import momentTz from 'moment-timezone';

@Pipe({
    name: 'datefr',
    standalone: true,
})
export class DatefrPipe implements PipeTransform {

    constructor() {
        locale('fr');
    }

    transform(value: any, format: any): any {
        return momentTz(value).tz('Europe/Paris').format(format);
    }
}
