import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {InformationDetenuService} from '../../services/information-detenu.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class InfosDetenuResolver implements Resolve<any> {
    constructor(
        private infosDetenuService: InformationDetenuService,
    ) {}

    resolve(activatedRoute: ActivatedRouteSnapshot): Observable<any> {
        return this.infosDetenuService.getTribunaux();
    }
}
