import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConfigProvider} from '@app/blocks/config/config.provider';
import {getInformationDetenuIdentifier, IInformationDetenu} from '../models/information-detenu.model';
import {Tribunal} from '../models/tribunaux.model';

export type EntityResponseType = HttpResponse<IInformationDetenu>;
export type EntityArrayResponseType = HttpResponse<IInformationDetenu[]>;

@Injectable({providedIn: 'root'})
export class InformationDetenuService {
    private resourceUrl = 'api/v1/information-detenus';
    private consultationUrl = this.configProvider.config.apiUrl + 'nedmasconsulterpermisvisite/' + this.resourceUrl;
    private traitementUrl = this.configProvider.config.apiUrl + 'nedmastraiterpermisvisite/' + this.resourceUrl;
    private tribunauxUrl = this.configProvider.config.apiUrl + 'nedmasreferentiel/api/v1/tribunaux';

    public isPrevenu = false;

    constructor(protected http: HttpClient, private configProvider: ConfigProvider) {
    }

    update(informationDetenu: IInformationDetenu): Observable<EntityResponseType> {
        return this.http.put<IInformationDetenu>(
            `${this.traitementUrl}/${getInformationDetenuIdentifier(informationDetenu) as number}`,
            informationDetenu,
            {observe: 'response'},
        );
    }

    verifierEcrouEtablissement(idEcrou: number, idEtablissement: string): Observable<boolean> {
        return this.http.get<boolean>(`${this.consultationUrl}/verifierEcrouEtablissement/${idEcrou}/${idEtablissement}`, {observe: 'body'});
    }

    verifierCategoriePenale(idEcrou: number, idEtablissement: string): Observable<boolean> {
        return this.http.get<boolean>(`${this.consultationUrl}/verifierCategoriePenale/${idEcrou}/${idEtablissement}`, {observe: 'body'});
    }

    getTribunaux() {
        return this.http.get<Tribunal[]>(`${this.tribunauxUrl}/genesis`);
    }

    checkOuvertureTribunal(codeTribunal: string) {
        return this.http.get<boolean>(`${this.tribunauxUrl}/${codeTribunal}/est-ouvert`);
    }

    checkExistenceAffaire(codeTribunal: string, codeEtab: string, ecrou: string) {
        return this.http.get<boolean>(`${this.consultationUrl}/famille/existence-affaire/${ecrou}/${codeEtab}/${codeTribunal}`);
    }

    categoriePenaleAChange(idDpv: number, idEcrou: number, idEtablissement: string): Observable<HttpResponse<boolean>> {
        return this.http.get<boolean>(`${this.consultationUrl}/categorie-penale-a-change/${idDpv}/${idEcrou}/${idEtablissement}`, {observe: 'response'});
    }
}
