import {Component, OnDestroy, OnInit} from '@angular/core';
import {AlertService} from '../service/alert.service';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { NgFor, NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'ned-alerte',
    template: `
        <div class="alerts alertPermisVisite w-100" role="alert">
            <div *ngFor="let alert of alerts; trackBy:trackById" class="container" [ngClass]="setClasses(alert)">
                <ngb-alert *ngIf="alert && alert.type && alert.msg" [type]="alert.type" (close)="alert.close(alerts)">
                    <p [innerHTML]="alert.msg"></p>
                    <button class="close-alert btn d-flex justify-content-between align-items-start"
                            (click)="alert.close()">
                        <span translate="global.menu.close"></span>
                        <ned-icon type="close" viewBox="0 0 16 16" [width]="16" class="col-auto"></ned-icon>
                    </button>
                </ngb-alert>
            </div>
        </div>`,
    standalone: true,
    imports: [
        NgFor,
        NgClass,
        NgIf,
        NgbAlert,
        TranslateModule,
        SvgIconComponent,
    ],
})
export class AlertComponent implements OnInit, OnDestroy {
    alerts: any[] = [];

    constructor(private alertService: AlertService) {}

    ngOnInit() {
        this.alerts = this.alertService.get();
    }

    setClasses(alert: any) {
        return {
            toast: !!alert.toast,
            [alert.position]: true,
        };
    }

    ngOnDestroy() {
        this.alerts = [];
    }

    trackById(index: number, item: any) {
        return item.id;
    }
}
