import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {CarteDetenuService} from '../services/carte-detenu.service';
import {UtilisateurConnecte} from '../models/utilisateur-connecte.model';

@Injectable()
export class AccueilFamilleResolver  {
    constructor(
        private carteDetenuService: CarteDetenuService,
    ) {}

    resolve(): Observable<UtilisateurConnecte> {
        return this.carteDetenuService.getAllCarteDetenus();
    }
}
