export enum TypePj {
    LIEN_DETENU = 'LIEN_DETENU',
    PIECE_IDENTITE = 'PIECE_IDENTITE',
    PHOTO = 'PHOTO',
    JUSTIFICATIF_DOMICILE = 'JUSTIFICATIF_DOMICILE',
    JUSTIFICATIF_MINEUR = 'JUSTIFICATIF_MINEUR',
    COMPLEMENTAIRE1 = 'COMPLEMENTAIRE1',
    COMPLEMENTAIRE2 = 'COMPLEMENTAIRE2',
    COMPLEMENTAIRE3 = 'COMPLEMENTAIRE3',
}
