import {HttpClient} from '@angular/common/http';
/**
 * Interface pour le stockage des informations de l'Authentification
 */
import {Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {ConfigProvider} from '../blocks/config/config.provider';

import {Principal} from '@app/core/auth/principal.service';
import {AUTH_ID_TOKEN} from '@shared/constants/authentification.constants';
import {interval, Observable, of, ReplaySubject, Subscription, throwError, timer} from 'rxjs';
import {HEADER_X_XSRF_TOKEN} from '../blocks/interceptor/auth.interceptor';
import {IdTokenDTO} from './models/id-token.model';
import {TutorielService} from '../tutoriel/tutoriel.service';
import {catchError, take, takeUntil, tap} from 'rxjs/operators';

export interface IAuthentification {
    state: string;
    nonce: string;
}

@Injectable({providedIn: 'root'})
export class AuthentificationService implements OnDestroy {
    /**
     * Identité du FS Portalis
     */
    private IDENTIFIANT_CLIENT = '';

    private ACR_VALUES = '';

    /**
     * End Point FC
     */
    private FC_URL = '';
    private FC_ENDPOINT_AUTHORIZE = '';
    private FC_ENDPOINT_LOGOUT = '';

    /**
     * URL de base l'application MAI PJU
     */
    private URI_BASE = '';
    private URL_CALLBACK = '';
    private URL_POST_LOGOUT = '';

    /**
     * Paramètres des appels REST
     * Ajout du paramètre gender dans le scope pour récuperer le sexe de l'utilisateur courant via l'identité pivot de franceConnect
     */
    private SCOPE = 'openid+family_name+given_name+birthdate+gender+email+preferred_username';
    private RESPONSE_TYPE = 'code';

    /**
     * Endpoint du controller pour l'authentification
     */
    public ENDPOINT_AUTHENTIFICATION = 'authentification';

    /**
     * Endpoint de la méthode pour l'authentification de FC
     */
    public ENDPOINT_AUTHENTIFICATION_FC = 'v1/authentificationFc';

    /**
     * Variable pour la detection de l'inactivite
     */
    public logoutTimer = 0;
    private numbers: Subscription | undefined;
    private controleVideo = timer(0, 30000);
    private lectureEnCours = false;
    private destroyed$ = new ReplaySubject(1);

    constructor(
        private router: Router,
        private http: HttpClient,
        private configProvider: ConfigProvider,
        private principal: Principal,
        private tutorielService: TutorielService,
    ) {
        this.initService();
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    initService() {
        // this.configProvider.load().subscribe(() => {
        this.IDENTIFIANT_CLIENT = this.configProvider.config.franceConnect.clientId;
        this.FC_URL = this.configProvider.config.franceConnect.baseUrl;
        this.FC_ENDPOINT_AUTHORIZE = this.configProvider.config.franceConnect.endpointAuthorize;
        this.FC_ENDPOINT_LOGOUT = this.configProvider.config.franceConnect.endpointLogout;
        this.ACR_VALUES = this.configProvider.config.franceConnect.acrValues;
        this.URI_BASE = encodeURIComponent(
            window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/',
        );
        this.URL_CALLBACK = this.configProvider.config.franceConnect.endpointCallback;
        this.URL_POST_LOGOUT = encodeURIComponent(this.configProvider.config.franceConnect.redirectLogout);
        // });
    }

    /**
     * Appel de la redirection pour l'authentification à FC.
     *
     * @param pageCallBack un string qui spécifie sur quel page l'utilisateur doit être redirigé après l'authentification FC
     * @return Un string qui correspond au nom et prénom de l'utlisateur connecté
     */
    getAuthorizeFC(pageCallBack: string): string {
        // Initialisation du callBack
        this.URL_CALLBACK = this.URL_CALLBACK + '?retour=' + pageCallBack;
        // Initialisation de l'interface Authentification
        const iAuthentification: IAuthentification = {
            state: this.randomString(50),
            nonce: this.randomString(50),
        };
        sessionStorage.setItem('IAuthentification', JSON.stringify(iAuthentification));
        return (
            this.FC_URL +
            this.FC_ENDPOINT_AUTHORIZE +
            '?scope=' +
            this.SCOPE +
            '&response_type=' +
            this.RESPONSE_TYPE +
            '&redirect_uri=' +
            this.URI_BASE +
            this.URL_CALLBACK +
            '&state=' +
            iAuthentification.state +
            '&nonce=' +
            iAuthentification.nonce +
            '&client_id=' +
            this.IDENTIFIANT_CLIENT +
            '&acr_values=' +
            this.ACR_VALUES
        );
    }

    /**
     * Appel REST pour s'authentifier à FC et récupérer l'identité pivot par la passerelle-ext
     * @param pCodeAuthorization code d'autorisation récupéré lors de l'autorisation
     * @param pState state renvoyé par l'authentification pour vérifier que c'est FC qui nous répond
     * @param pRetourCallBack url vers lequel l'utilisateur est redirigé une fois l'authentification FC réussit
     */
    authentificationFc(pCodeAuthorization: string, pState: string, pRetourCallBack: string) {
        let iAuthentification: any;
        if (sessionStorage.getItem('IAuthentification')) {
            iAuthentification = JSON.parse(sessionStorage.getItem('IAuthentification')!);
        }
        if (iAuthentification && pState === iAuthentification.state) {
            sessionStorage.removeItem('IAuthentification');

            return this.http.get<IdTokenDTO>(
                    this.configProvider.config.apiUrl +
                    this.ENDPOINT_AUTHENTIFICATION +
                    '/' +
                    this.ENDPOINT_AUTHENTIFICATION_FC +
                    '/' +
                    pCodeAuthorization + '?retour=' + pRetourCallBack,
                )
                .pipe(
                    tap(response => {
                        const xsrfToken = sessionStorage.getItem(HEADER_X_XSRF_TOKEN);
                        if (xsrfToken) {
                            this.storeTokensInformations(response);
                            this.principal.authenticate(xsrfToken);
                        } else {
                            throwError(() => new Error('La déconnexion a échoué'));
                        }

                    }),
                );
        }

        return throwError(() => new Error('La déconnexion a échoué'));
    }

    /**
     * Appel de déconnexion de l'utilisateur à FranceConnect par le click au menu
     */
    logoutByClick(): void {
        if (sessionStorage.getItem(AUTH_ID_TOKEN)) {
            const urlDeconnectionFC =
                this.FC_URL +
                this.FC_ENDPOINT_LOGOUT +
                '?id_token_hint=' +
                sessionStorage.getItem(AUTH_ID_TOKEN) +
                '&state=' +
                this.randomString(50) +
                '&post_logout_redirect_uri=' +
                this.URL_POST_LOGOUT;

            this.deconnexion().subscribe({
                complete: () => {
                    localStorage.clear();
                    sessionStorage.clear();
                    setTimeout(() => {
                        window.open(urlDeconnectionFC, '_self');
                    });
                },
            });
        } else {
            this.principal.authenticate('');
            this.router.navigate(['']);
        }
    }

    /**
     * Méthode pour stocker les informations de FC necessaires au fonctionnement du PJU
     * @param idTokenDTO
     */
    private storeTokensInformations(idTokenDTO: IdTokenDTO): void {
        // Token Id pour la deconnexion
        if (idTokenDTO.idToken) {
            sessionStorage.setItem(AUTH_ID_TOKEN, idTokenDTO.idToken);
        }
    }

    private randomString(length: number) {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }

    detectionActivite() {
        document.addEventListener('click', () => {
            this.resetCompteurInactivite();
        });
        this.detectionVideo();
        this.numbers = interval(1000)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.logoutTimer++;
                if (this.logoutTimer === Number(this.configProvider.config.logoutTimer)) {
                    this.logoutByClick();
                }
                if (!this.principal.isAuthenticated()) {
                    this.numbers!.unsubscribe();
                }
            });
    }

    resetCompteurInactivite() {
        this.logoutTimer = 0;
    }

    detectionVideo() {
        this.tutorielService.lectureVideoObservable.subscribe(lecture => {
            this.lectureEnCours = lecture;
        });
        this.controleVideo.subscribe(() => {
            if (this.lectureEnCours) {
                this.resetCompteurInactivite();
                this.appelRefreshToken();
            }
        });
    }

    appelRefreshToken() {
        this.keepAlive().pipe(take(1)).subscribe();
    }

    /**
     * Renvoi un cookie expiré pour la déconnexion.
     */
    deconnexion(): Observable<any> {
        return this.http
            .get(this.configProvider.config.apiUrl + 'api/v1/deconnexion', {observe: 'response'})
            .pipe(
                catchError(() => of(null)),
            );
    }

    /**
     * Keep alive pour refresh le cookie
     */
    keepAlive() {
        return this.http.get<any>(this.configProvider.config.apiUrl + 'nedmasutilisateurfamille/api/v1/keep-alive');
    }
}
