<footer class="footer" role="contentinfo">
    <div class="container">
        <div class="row">
            <div class="col-auto px-0">
                <img alt="logo ministère de la justice" class="logoFooter"
                     src="/content/images/logo-ministere-de-la-justice_footer.svg" />
            </div>
            <div class="w-100 d-sm-none"></div>
            <div class="col">
                <div class="d-flex justify-content-between">
                    <h3 class="custom footerTitle">penitentiaire.justice.fr</h3>
                </div>
                <div class="row">
                    <span class="sr-only col-auto" id="footerLabel" translate="footer.label"></span>

                    <nav class="navbar navbar-expand-md justify-content-start mainFooterNavbar pl-0" role="navigation"
                         aria-labelledby="footerLabel">
                        <ul class="navbar-nav text-nowrap">
                            <li class="nav-item">
                                <a (click)="goToContact()" class="nav-link cursor-pointer">
                                    <span translate="footer.contact"></span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a (click)="goToPlan()" class="nav-link cursor-pointer">
                                    <span translate="footer.plan-site"></span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a (click)="goToMentions()" class="nav-link cursor-pointer">
                                    <span translate="footer.mentions"></span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a (click)="goToAccessibilite()" class="nav-link cursor-pointer">
                                    <span translate="footer.accessibilite.titre"></span>
                                    <span translate="footer.accessibilite.conformite"></span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div class="pts-Footer-toTop col-auto ms-auto align-self-sm-center">
                <a class="px-2 d-block text-white mb-lg-5" (click)="goToTop()" title="Retour haut de page"
                   aria-labelledby="gotop">
                    <div class="text-nowrap text-end py-2">
                        <img src="/content/images/pictos/arrow-top.svg" alt="Revenir en haut de page"
                             class="pe-2 align-baseline">
                        <span id="gotop" class="text-nowrap" translate="footer.accessibilite.top"></span>
                    </div>
                </a>
            </div>
        </div>
    </div>
    <nav class="navbar navbar-expand-md justify-content-center secondaryFooterNavbar" role="navigation"
         aria-labelledby="footerLabel">
        <div class="container d-block">
            <div class="row justify-content-md-center">
                <ul class="navbar-nav justify-content-center">
                    <li class="nav-item">
                        <a class="nav-link" href="https://www.gouvernement.fr/" rel="noopener noreferrer"
                           target="_blank">
                            Gouvernement.fr
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://www.legifrance.gouv.fr/"
                           rel="noopener noreferrer"
                           target="_blank">
                            Legifrance.gouv.fr
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://www.justice.fr/" rel="noopener noreferrer"
                           target="_blank">
                            Justice.fr
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://www.service-public.fr/" rel="noopener noreferrer"
                           target="_blank">
                            Service-public.fr
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://www.france.fr/fr" rel="noopener noreferrer"
                           target="_blank">
                            France.fr
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://www.data.gouv.fr/fr/" rel="noopener noreferrer"
                           target="_blank">
                            Data.gouv.fr
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    <div class="container-fluid">
        <p class="legals">© Ministère de la Justice - {{mois + ' ' + annee}} - Penitentiaire.justice.fr, le site
            officiel à destination des proches des détenus.</p>
        <div class="text-center">
            <span class="legals">{{version.toLowerCase()}}</span>
        </div>
    </div>

</footer>
