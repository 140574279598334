import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class NedTranslateService extends TranslateService {

    //Surcouche du service suite à la montée de version et au changement de plugin visant à merge la fichier json de traduction
    override get(key: string | Array<string>, interpolateParams?: Object): Observable<any> {
        key = 'translations.' + key;
        return super.get(key, interpolateParams);
    }

    override instant(key: string | Array<string>, interpolateParams = {}): Observable<any> {
        key = 'translations.' + key;
        return super.instant(key, interpolateParams);
    }

}
