import {Component, OnDestroy} from '@angular/core';
import {RouterModule, RouterOutlet} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {LoadingService} from '@shared/loading-interceptor/loading.service';
import {TunnelService} from '@shared/tunnel-interceptor/tunnel.service';
import {delay, takeUntil} from 'rxjs/operators';
import {ReplaySubject} from 'rxjs';
import {LoaderComponent} from '@shared/loader/loader.component';
import {AlertComponent, FindLanguageFromKeyPipe} from '@app/shared';
import {CommonModule} from '@angular/common';
import {ConfigProvider} from '@app/blocks/config/config.provider';
import {FooterComponent} from '@app/layouts/footer/footer.component';
import {NavbarComponent} from '@app/layouts/navbar/navbar.component';

@Component({
    selector: 'app-root',
    templateUrl: './main.component.html',
    standalone: true,
    imports: [
        RouterModule,
        CommonModule,
        RouterOutlet,
        LoaderComponent,
        TranslateModule,
        FindLanguageFromKeyPipe,
        AlertComponent,
        FooterComponent,
        NavbarComponent,
    ],
})
export class NedMainComponent implements OnDestroy {
    isLoading$;
    isTunnel: boolean = false;
    private _hasFooter = false;
    version: string;
    mois: string;
    annee: string;
    private destroyed$ = new ReplaySubject(1);

    constructor(
        private loadingService: LoadingService,
        private tunnelService: TunnelService,
        private configProvider: ConfigProvider,
    ) {
        this.isLoading$ = this.loadingService.getLoading()
            .pipe(delay(0));
        this.tunnelService.getTunnel().pipe(takeUntil(this.destroyed$))
            .subscribe(response => this.isTunnel = response);
        this.tunnelService.getHasFooterForced().pipe(takeUntil(this.destroyed$))
            .subscribe(response => this._hasFooter = response);
        this.version = this.configProvider.config.version || '';
        this.mois = this.configProvider.config.moisInstallation || '';
        this.annee = this.configProvider.config.anneeInstallation || '';
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    scrollTo($event: Event, id: string) {
        const div = document.getElementById(id);

        const focusable = div && (id === 'footer'
            ? div.querySelector('.logoFooter')
            : div.querySelector('h1'));
        if (focusable) {
            if (!focusable.hasAttribute('tabindex')) {
                focusable.setAttribute('tabindex', '0');
            }
            (focusable as HTMLElement).focus();
            focusable.removeAttribute('tabindex');
        }
        div && window.scrollTo(0, div.getBoundingClientRect().top + window.scrollY);
    }

}
