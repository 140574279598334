import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {Principal} from '@app/core/auth/principal.service';

/**
 * @whatItDoes Conditionally includes an HTML element if current user has any
 * of the authorities passed as the `expression`.
 *
 * @howToUse
 * ```
 *     <some-element *jhiHasAnyAuthority="'ROLE_ADMIN'">...</some-element>
 *
 *     <some-element *jhiHasAnyAuthority="['ROLE_ADMIN', 'ROLE_USER']">...</some-element>
 * ```
 */
@Directive({
    selector: '[nedHasAnyAuthority]',
    standalone: true,
})
export class HasAnyAuthorityDirective {
    private authorities: string[] = [];

    constructor(private principal: Principal, private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) {}

    @Input()
    set nedHasAnyAuthority(value: string | string[]) {
        this.authorities = typeof value === 'string' ? [value] : value;
        this.updateView();
        // Get notified each time authentication state changes.
        this.principal.getAuthenticationState().subscribe(() => this.updateView());
    }

    private updateView(): void {
        const result = this.principal.hasAnyAuthority(this.authorities);
        this.viewContainerRef.clear();
        if (result) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
    }
}
