import {Injectable} from '@angular/core';

import {of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ContactService} from './contact.service';
import {UtilisateurGP} from '@app/mon-compte/models/utilisateurGP.model';

@Injectable({
    providedIn: 'root',
})
export class ContactFormResolver  {

    constructor(private contactService: ContactService) {}

    resolve() {
        return this.contactService.getUtilisateurGP()
            .pipe(
                catchError(() => of(null)),
            );
    }
}
