import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import {catchError} from 'rxjs/operators';
import {DemandePermisVisite} from '@app/demande-permis-visite/models/demande-permis-visite.model';
import {DemandePermisVisiteService} from '@app/demande-permis-visite/services/demande-permis-visite.service';

@Injectable()
export class DemandePermisVisiteConsulterResolver  {
    constructor(private demandePermisVisiteService: DemandePermisVisiteService) {
    }

    resolve(route: ActivatedRouteSnapshot): Observable<DemandePermisVisite[]> {
        return this.demandePermisVisiteService.getAllDemandeByUser().pipe(
            catchError(error => {
                return of([]);
            }),
        );
    }
}
