import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AuthentificationService} from '../authentification.service';
import {Data} from '@shared/util/data.model';
import {AlertService} from '@shared/service/alert.service';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {RouterLink} from '@angular/router';
import {SvgIconComponent} from '@shared/svg-icon/svg-icon.component';

@Component({
    selector: 'ned-authentification-before',
    templateUrl: './authentification-before.component.html',
    providers: [],
    standalone: true,
    imports: [
        TranslateModule,
        SvgIconComponent,
        RouterLink,
    ],
})

/**
 * Component pour la gestion de l'authentification
 */
export class AuthentificationBeforeComponent implements AfterViewInit, OnInit {

    constructor(
        private authentificationService: AuthentificationService,
        private data: Data,
        private alertService: AlertService,
        private translateService: TranslateService,
    ) {}

    ngOnInit() {
        if (this.data.storage) {
            this.alertService.addAlert({type: 'danger', msg: this.data.storage}, []);
        }
    }

    connectionToFC(pageCallBack: string): void {
        window.open(this.authentificationService.getAuthorizeFC(pageCallBack), '_self');
    }

    allocationNomService(name: string) {
        switch (name) {
            case 'parloirs':
                return this.translateService.instant('hors-connexion.services.parloir.libelle');
            case 'permisVisite':
                return this.translateService.instant('hors-connexion.services.demande-permis.titre');
            case 'pecule':
                return this.translateService.instant('hors-connexion.services.pecule.titre-pecule');
            default:
                return null;
        }
    }

    ngAfterViewInit() {
        sessionStorage.clear();
        localStorage.clear();
    }
}
