import {ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges} from '@angular/core';

// Import the application components and services.
import {UniqueIDService} from './unique-id.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ned-icon',
    templateUrl: './svg-icon.component.html',
    styleUrls: ['./svg-icon.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf],
})
export class SvgIconComponent implements OnChanges {
    @Input() title: string = '';
    @Input() type: string = '';
    @Input() viewBox: string | null = null;

    @HostBinding('attr.title') ariaTitle: string | null = null;
    @HostBinding('attr.aria-hidden') ariaHidden: boolean | null = true;
    @HostBinding('attr.aria-labelledby') ariaLabelledBy: string | null = null;
    @HostBinding('attr.role') role: string = 'img';
    @HostBinding('style.width.px') @Input() width: number | null = null;
    @HostBinding('style.height.px') @Input() height: number | null = null;

    private uniqueIDService: UniqueIDService;

    constructor(uniqueIDService: UniqueIDService) {
        this.uniqueIDService = uniqueIDService;
    }

    public ngOnChanges(): void {

        if (this.title) {

            // If a title was provided, it means that this icon is more than just a
            // decorative element. As such, let's try to make it more accessible to
            // screen-readers.
            this.ariaHidden = null;
            this.ariaLabelledBy = (this.ariaLabelledBy || this.uniqueIDService.next());
            this.ariaTitle = this.title;

        } else {

            // If there is no title, we want to hide this icon from screen-readers.
            this.ariaHidden = true;
            this.ariaLabelledBy = null;
            this.ariaTitle = null;

        }

    }

    get computedWidth() {
        if (this.width) {
            return `${this.width}px`;
        }
        if (this.viewBox) {
            return `${this.viewBox.split(' ')[2]}px`;
        }
        return '1rem';
    }

    get computedHeight() {
        if (this.height) {
            return `${this.height}px`;
        }
        if (this.viewBox) {
            return null;
        }
        if (this.width) {
            return `${this.width}px`;
        }

        return '1rem';
    }
}
