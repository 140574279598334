import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LoadingService} from './loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    // Route à inclure
    routeWithLoading = [
        'nedmascommunicationfamille/api/v1/rubriques-faqs/liste',
        'nedmascommunicationfamille/api/v1/rubriques-description-etablissements/code-etablissement',
        'nedmastraiterparloir/api/v1/rdvparloirs',
        'nedmastraiterpermisvisite/api/v1/permisvisites',
        'nedmasconsulterpermisvisite/api/v1/permisvisites',
        'nedmasconsulterparloir/api/v1/rdvparloirs',
        'nedmasconsulterparloir/api/v1/creneauxparloirs/verifier',
        'nedmasconsulterpermisvisite/api/v1/permisvisites/listevisiteurs',
        'nedmasconsulterparloir/api/v1/creneauxparloirs/',
        'v1/authentificationFc/',
        'nedmasutilisateurfamille/api/v1/verifier/connexion',
        'nedmasutilisateurfamille/api/v1/carte-detenus/famille',
        'nedmasutilisateurfamille/api/v1/alertes/utilisateur',
        'nedmasreferentiel/api/v1/etablissement/verifier',
        'nedmasconsulterpermisvisite/api/v1/information-detenus',
        'nedmastraiterpermisvisite/api/v1/information-detenus',
        'nedmasconsulterpermisvisite/api/v1/demande-permis-visites',
        'nedmasconsulterpermisvisite/api/v1/demande-permis-visites/liste-dpv-famille',
        'nedmasconsulterpermisvisite/api/v1/demande-permis-visites/idDpv',
        'nedmastraiterpermisvisite/api/v1/demande-permis-visites',
        'nedmasconsulterpermisvisite/api/v1/information-detenus/verifierEcrouEtablissement',
        'nedmasconsulterpermisvisite/api/v1/information-detenus/verifierCategoriePenale',
        'nedmasutilisateurfamille/api/v1/utilisateur-courant',
        'nedmasutilisateurfamille/api/v1/utilisateur-gps',
        'nedmasconsulterpermisvisite/api/v1/demande-permis-visites/existencePvGenesis',
        'nedmasconsulterpermisvisite/api/v1/demande-permis-visites/existencePvMajeurGenesis',
        'nedmasconsulterpermisvisite/api/v1/demande-permis-visites/existenceDemandeActive',
        'nedmasconsulterpermisvisite/api/v1/gen-ln-pays/code',
        'nedmasconsulterpermisvisite/api/v1/gen-ln-commune-insees/id',
        'nedmasconsulterpermisvisite/api/v1/gen-ln-nationalites/code',
        'nedmasconsulterpermisvisite/api/v1/gen-ln-type-rel-det-permis/findBySexeAndParente',
        'nedmasconsulterpermisvisite/api/v1/gen-ln-type-rel-det-permis',
        'nedmasconsulterpermisvisite/api/v1/piece-justificative-ged',
        'nedmastraiterpermisvisite/api/v1/piece-justificative-ged',
        'nedmastraiterpermisvisite/api/v1/piece-justificatives',
        'nedmastraiterpermisvisite/api/v1/piece-jointe-demandes',
        'nedmastraiterpermisvisite/api/v1/statut-piece-justificatives',
        'nedmasconsulterpermisvisite/api/v1/gen-ln-type-piece-identites',
        'nedmaspecule/api/v1/pecules/virements6Mois/',
        'nedmascommunicationfamille/api/v1/demandesupport',
        'nedmastraiterpermisvisite/api/v1/demande-permis-visites/famille/transmissionNouvelleDemandePermisVisite',
        'nedmastraiterpermisvisite/api/v1/demande-permis-visites/famille/transmissionDemandePermisVisiteCorrigee',
        'nedmastraiterpermisvisite/api/v1/demande-permis-visites/famille/creationPieceJustificative',
        'nedmastraiterpermisvisite/api/v1/demande-permis-visites/famille/updateDemandePermisVisiteDemandeMineurEmancipe',
        'nedmastraiterpermisvisite/api/v1/demande-permis-visites/famille/updateDemandePermisVisiteInfosMajeurRattache',
        'nedmastraiterpermisvisite/api/v1/demande-permis-visites/famille/updateDemandePermisVisiteLienVisiteurDetenu',
        'nedmastraiterpermisvisite/api/v1/demande-permis-visites/famille/updateDemandePermisVisiteChoixBeneficiaire',
        'nedmastraiterpermisvisite/api/v1/demande-permis-visites/famille/createDemandePermisVisiteInfosVisiteur',
        'nedmastraiterpermisvisite/api/v1/demande-permis-visites/famille/updateDemandePermisVisiteInfosComplementairesVisiteur',
        'nedmaspecule/api/v1/virements',
        'nedmaspecule/api/v1/recuperationParamPecule',
    ];

    constructor(private loadingService: LoadingService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const routeAddFound = this.routeWithLoading.filter((route: string) => req.url.includes(route)).length > 0;
        if (routeAddFound) {
            this.loadingService.pushInQueue(req.url);
        }
        return next.handle(req).pipe(
            finalize(() => {
                const routeRemFound = this.routeWithLoading.filter(
                    (route: string) => req.url.includes(route),
                ).length > 0;
                if (routeRemFound) {
                    this.loadingService.popFromQueue();
                }
            }),
        );
    }
}
