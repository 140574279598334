import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import { NgIf } from '@angular/common';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'ned-popin',
    templateUrl: './composant-popin-two-choices-shared.component.html',
    standalone: true,
    imports: [
        TranslateModule,
        SvgIconComponent,
        NgIf,
    ],
})
export class ComposantPopinTwoChoicesSharedComponent {
    titrePopIn: string | undefined;
    messagePopIn: string | undefined;
    libelleAccepter: string | undefined;
    libelleRefuser: string | undefined;

    faTimes = faTimes;

    constructor(
        public activeModal: NgbActiveModal,
    ) {}
}
