<div (keydown.escape)="activeModal.close(false)" class="c-card c-card-small">
    <div class="modal-header d-flex justify-content-end">
        <button class="text-info close-alert btn d-flex justify-content-between align-items-start"
                attr.aria-label="{{'global.menu.close' | translate}}"
                (click)="activeModal.close(false)">
            <span translate="global.menu.close"></span>
            <ned-icon type="close" class="col-auto"></ned-icon>
        </button>
    </div>
    <div class="c-card-small--body">
        <div class="modal-body">
            <h2 *ngIf="titrePopIn" class="h4 fw-bold">{{titrePopIn}}</h2>
            <p class="c-text c-text-home mt-3" role="alert" aria-live="assertive"
               style="max-width:100%">{{ messagePopIn }}</p>
        </div>
        <div class="modal-footer pt-5 justify-content-center">
            <button type="button" class="btn btn-outline-info" attr.aria-label="{{ libelleRefuser }}"
                    (click)="activeModal.close(false)">{{ libelleRefuser }}</button>
            <button type="button" class="btn btn-info" attr.aria-label="{{ libelleAccepter }}"
                    (click)="activeModal.close(true)">{{ libelleAccepter }}</button>
        </div>
    </div>
</div>
