import {Router, RouterModule} from '@angular/router';
import {Component} from '@angular/core';
import {ConfigProvider} from '@app/blocks/config/config.provider';
import {TranslateModule} from '@ngx-translate/core';

@Component({
    selector: 'ned-footer',
    templateUrl: './footer.component.html',
    standalone: true,
    imports: [TranslateModule, RouterModule],
})
export class FooterComponent {
    version: string;
    mois: string;
    annee: string;

    constructor(
        private router: Router,
        private configProvider: ConfigProvider,
    ) {
        this.version = this.configProvider.config.version || '';
        this.mois = this.configProvider.config.moisInstallation || '';
        this.annee = this.configProvider.config.anneeInstallation || '';
    }

    goToTop() {
        window.scrollTo(0, 0);
        const focusable: HTMLElement | null = document.querySelector('.header .navbar-brand');
        focusable?.focus();
    }

    goToMentions() {
        this.router.navigate(['/mentions-legales']);
        this.goToTop();
    }

    goToPlan() {
        this.router.navigate(['/plan-du-site']);
        this.goToTop();
    }

    goToAccessibilite() {
        this.router.navigate(['/accessibilite']);
        this.goToTop();
    }

    goToContact() {
        this.router.navigate(['/contact']);
        this.goToTop();
    }
}
