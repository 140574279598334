import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {CommunicationService} from '../communication.service';

@Injectable()
export class EtablissementResolver  {
    constructor(private communicationService: CommunicationService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.communicationService.getEtablissementFAQ(route.params['id']).pipe(
            catchError(err => {
                return of(null);
            }),
        );
    }
}
