import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {RdvParloirService} from '@app/rdv-parloir/rdv-parloir.service';
import {Data} from '@shared/util/data.model';
import {RdvParloir} from '../../models/rdv-parloir.model';

@Injectable()
export class RdvParloirResumeResolver  {

    rdvParloir?: RdvParloir;

    constructor(
        private rdvParloirService: RdvParloirService,
        private data: Data,
    ) {}

    resolve(): Observable<any> {
        if (!this.data.storage) {
            return of(null);
        }

        this.rdvParloir = this.data.storage;

        if (this.rdvParloir?.permisPrincipal?.codeEtablissement) {

            return this.rdvParloirService.getTempsPresenceAvantRdv(this.rdvParloir.permisPrincipal.codeEtablissement).pipe(
                catchError(() => {
                    return of(30);
                }),
            );
        }

        return of(null);
    }
}
