import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

import {ConfigProvider} from '@app/blocks/config/config.provider';
import {UtilisateurFamille} from '@app/connexion/models/utilisateur-famille.model';

@Injectable({providedIn: 'root'})
export class AccountService {
    constructor(private http: HttpClient, private configProvider: ConfigProvider) {}

    get(): Observable<HttpResponse<UtilisateurFamille>> {
        return this.http.get<UtilisateurFamille>(this.configProvider.config.apiUrl + 'api/v1/account-famille', {observe: 'response'});
    }

    save(account: any): Observable<HttpResponse<any>> {
        return this.http.post(this.configProvider.config.apiUrl + 'api/v1/account-famille', account, {observe: 'response'});
    }
}
