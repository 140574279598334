import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {PermisVisite} from '../../models/permis-visite.model';
import {catchError} from 'rxjs/operators';
import {RdvParloirService} from '@app/rdv-parloir/rdv-parloir.service';
import {TranslateService} from '@ngx-translate/core';
import {
    ComposantPopInTwoChoicesSharedService,
} from '@shared/pop-in-two-choices/composant-popin-two-choices-shared.service';
import {Location} from '@angular/common';

@Injectable()
export class RdvParloirReserverResolver  {
    constructor(
        private rdvParloirService: RdvParloirService,
        private router: Router,
        private translateService: TranslateService,
        private composantPopInTwoChoicesService: ComposantPopInTwoChoicesSharedService,
        private location: Location,
    ) {
    }

    resolve(): Observable<PermisVisite[]> {
        return this.rdvParloirService.getPermisVisite((this.location.getState() as {
            [key: string]: any
        })?.['idCarteDetenu'] || '')
            .pipe(
                catchError(() => {
                    const message = (this.translateService.instant('rdv-parloir.messages.aucunPermisVisite'));
                    const libelleBoutonAcceptation = this.translateService.instant('mon-compte.bouton.oui');
                    const libelleBoutonRefus = this.translateService.instant('mon-compte.bouton.non');
                    this.router.navigate(['/parloir']);

                    this.composantPopInTwoChoicesService
                        .popIn(message, libelleBoutonAcceptation, libelleBoutonRefus)
                        .result.then((resultat: boolean) => {
                        // Si l'utilisateur confirme de façon positive
                        if (resultat) {
                            // Si le permis V2 est actif
                            this.router.navigate(['/permisvisiteV2/'], {state: {idCarteDetenu: ''}});
                        }
                    });
                    return of([]);
                }),
            );
    }
}
