import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConfigProvider} from '../blocks/config/config.provider';
import {AlerteFamille} from './models/AlerteFamille.model';

@Injectable({providedIn: 'root'})
export class AlerteFamilleService {

    constructor(
        private http: HttpClient,
        private configProvider: ConfigProvider,
    ) {}

    getAlerteUtilisateur() {
        const headers = new HttpHeaders();
        const url = this.configProvider.config.apiUrl + 'nedmasutilisateurfamille/api/v1/alertes/utilisateur';
        return this.http.get<AlerteFamille[]>(url, {headers});
    }

    deleteAlerteUtilisateur(alerteId: number) {
        const url = this.configProvider.config.apiUrl + 'nedmasutilisateurfamille/api/v1/alertes/' + alerteId;
        return this.http.delete(url);
    }
}
