import {Routes} from '@angular/router';
import {authentificationBeforeRoute} from './authentification-before/authentification-before.route';
import {authentificationAfterRoute} from './authentification-after/authentification-after.route';

const AUTHENTIFICATION_ROUTES = [authentificationBeforeRoute, authentificationAfterRoute];

export const authentificationRoutes: Routes = [
    {
        path: '',
        children: AUTHENTIFICATION_ROUTES,
    },
];
