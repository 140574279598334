import {Observable} from 'rxjs';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';

import {ConfigProvider} from '../config/config.provider';
import {tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';

export const HEADER_X_XSRF_TOKEN = 'X-XSRF-TOKEN';

@Injectable({providedIn: 'root'})
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private configProvider: ConfigProvider,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (
            !request?.url ||
            (/^http/.test(request.url) && !request.url.startsWith(this.configProvider.config.apiUrl))
        ) {
            return next.handle(request);
        }

        // Clone the request and set the new header in one step.
        let authReq;
        const xcrf = sessionStorage.getItem(HEADER_X_XSRF_TOKEN);

        if (!request.headers.get(HEADER_X_XSRF_TOKEN) && xcrf) {
            authReq = request.clone({
                withCredentials: true,
                setHeaders: {'X-XSRF-TOKEN': sessionStorage.getItem(HEADER_X_XSRF_TOKEN) as string},
            });
        } else {
            authReq = request.clone({withCredentials: true});
        }


        // send cloned request with header to the next handler.
        return next.handle(authReq).pipe(
            tap({
                next:
                    event => {
                        if (event instanceof HttpResponse) {
                            this.getXSRFTokenHeader(event);
                        }
                        return event;
                    },
                error: error => {
                    if (error instanceof HttpErrorResponse) {
                        this.getXSRFTokenHeaderError(error);
                    }
                    console.error(error.status);
                    console.error(error.message);
                },
            }),
        );
    }

    /**
     * Méthode qui permet de stocker en session storage le token xcrf
     * @param pResponse : response contenant le cookie
     */
    private getXSRFTokenHeader(pResponse: HttpResponse<any>): void {
        // Gestion de la réponse
        // Si le header 'X_XSRF_TOKEN' est rempli, alors un xcrf token
        // a été créé ou mis à jour
        // On met donc à jour le sessionStorage

        const resHeaders = pResponse.headers;
        const XSRFHeader = resHeaders.get(HEADER_X_XSRF_TOKEN);

        if (XSRFHeader) {
            sessionStorage.setItem(HEADER_X_XSRF_TOKEN, XSRFHeader);
        }
    }

    /**
     * Méthode qui permet de stocker en session storage le token xcrf
     * @param pResponse : response contenant le cookie
     */
    private getXSRFTokenHeaderError(pResponse: HttpErrorResponse): void {
        // Gestion de la reponse
        // Si le header 'X_XSRF_TOKEN' est rempli, alors un xcrf token a ete cree ou mis a jour
        // On met donc à jour le sessionStorage
        const resHeaders = pResponse.headers;
        const XSRFHeader = resHeaders.get(HEADER_X_XSRF_TOKEN);

        if (XSRFHeader) {
            sessionStorage.setItem(HEADER_X_XSRF_TOKEN, XSRFHeader);
        }
    }

}
