import {Injectable} from '@angular/core';
import {PermisVisiteV2Service} from '@pv/permis-visite-2.service';
import {Observable, of} from 'rxjs';
import {PermisVisite} from '@pv/models/permis-visite.model';
import { Router } from '@angular/router';
import {catchError} from 'rxjs/operators';

@Injectable()
export class PermisVisiteEnregistresResolver  {
    constructor(private permisVisiteService: PermisVisiteV2Service, private router: Router) {}

    resolve(): Observable<PermisVisite[]> {
        return this.permisVisiteService.getListePermisVisite(this.router.getCurrentNavigation()!.extras!.state!['idCarteDetenu']!)
            .pipe(
                catchError(error => {
                    return of([] as PermisVisite[]);
                }),
            );
    }
}
