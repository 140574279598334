import {Injectable} from '@angular/core';
import {PermisVisite} from '@pv/models/permis-visite.model';
import { Router } from '@angular/router';
import {PermisVisiteV2Service} from '@pv/permis-visite-2.service';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';


@Injectable()
export class PageAccueilResolver  {
    constructor(
        private permisVisiteService: PermisVisiteV2Service,
        private router: Router,
    ) {}

    resolve(): Observable<PermisVisite[]> {
        const idCarteDetenu = this.router.getCurrentNavigation()?.extras.state?.['idCarteDetenu'] || '';
        return this.permisVisiteService.getListePermisVisite(idCarteDetenu).pipe(
            catchError(_ => {
                return of([]);
            }),
        );
    }
}
