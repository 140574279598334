import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import { Router } from '@angular/router';
import {catchError} from 'rxjs/operators';
import {IDemandePermisVisite} from '@app/demande-permis-visite/models/demande-permis-visite.model';
import {DemandePermisVisiteService} from '@app/demande-permis-visite/services/demande-permis-visite.service';

@Injectable()
export class PermisVisiteDemandesResolver  {
    constructor(private demandePermisVisiteService: DemandePermisVisiteService, private router: Router) {}

    resolve(): Observable<IDemandePermisVisite[]> {
        return this.demandePermisVisiteService.getAllDemandeByUser()
            .pipe(
                catchError(error => of([] as IDemandePermisVisite[])),
            );
    }
}
