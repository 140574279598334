<div [ngClass]="{ overlay: isLoading$ | async, 'is-tunnel': isTunnel }">
    <div class="skiplinks">
        <div class="container">
            <ul class="skiplinks__list list-unstyled">
                <li>
                    <a (click)="scrollTo($event, 'main')" tabindex="0"
                       class="skiplinks__link cursor-pointer">{{ 'global.menu.go-to-content' | translate }}</a>
                </li>
                <li>
                    <a (click)="scrollTo($event, 'footer')" tabindex="0"
                       class="skiplinks__link cursor-pointer">{{ 'global.menu.go-to-footer' | translate }}</a>
                </li>
            </ul>
        </div>
    </div>
    <ned-navbar *ngIf="!isTunnel"></ned-navbar>
    <ned-alerte aria-live="assertive"></ned-alerte>

    <main class="mainContent" id="main" role="main"
          [ngStyle]="{'padding-top': (isTunnel) ? '0' : '40px'}">
        <router-outlet></router-outlet>
        <router-outlet name="popup"></router-outlet>
    </main>

    <ned-footer></ned-footer>
</div>
<ned-loader *ngIf="isLoading$ | async"></ned-loader>
