import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router, RouterLink, RouterLinkActive} from '@angular/router';
import {NgbCollapseModule, NgbDropdown, NgbDropdownModule, NgbModalRef,} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {LoginModalService} from '@app/core/login/login-modal.service';
import {Principal} from '@app/core/auth/principal.service';
import {AuthentificationService} from '@app/security/authentification.service';
import {Globals} from '@shared/util/globals';
import {delay, take} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {faBars, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgClass, NgIf} from '@angular/common';
import {LANGUAGES} from '@app/core/language/language.constants';

@Component({
    selector: 'ned-navbar',
    templateUrl: './navbar.component.html',
    standalone: true,
    imports: [
        RouterLinkActive,
        RouterLink,
        NgIf,
        TranslateModule,
        FontAwesomeModule,
        NgbCollapseModule,
        NgClass,
        NgbDropdownModule,
    ],
})
export class NavbarComponent implements OnInit, OnDestroy {

    @ViewChild('accountLink', {static: false}) accountLink!: ElementRef<HTMLElement>;
    @ViewChild('firstNavLink', {static: false}) firstNavLink!: ElementRef<HTMLElement>;
    @ViewChild('rla8', {static: false}) rla8!: ElementRef<object>;
    @ViewChild('dropAideEl', {static: false}) dropAideEl!: ElementRef<HTMLElement>;

    isNavbarCollapsed: boolean;
    languages: string[];
    modalRef: NgbModalRef | undefined;
    nomPrenom = '';
    authenticate = false;
    recupeNomPrenom = false;
    authSubscription: Subscription | undefined;

    faBars = faBars;
    faTimes = faTimes;

    constructor(
        private languageService: TranslateService,
        // private languageHelper: NedLanguageHelper,
        private principal: Principal,
        private loginModalService: LoginModalService,
        private router: Router,
        private authService: AuthentificationService,
        public globals: Globals,
    ) {
        this.isNavbarCollapsed = true;
        this.languages = LANGUAGES;

        // Subscription à l'authentification pour gestion déconnexion
        if (!this.authSubscription) {
            this.authSubscription = this.principal.getAuthenticationState()
                .pipe(delay(0))
                .subscribe(() => {
                    this.checkAuthentification();
                });
        }
    }

    ngOnInit() {
        // Vérification de l'authentification
        this.checkAuthentification();
    }

    ngOnDestroy() {
        if (this.authSubscription) {
            this.authSubscription.unsubscribe();
        }
    }

    changeLanguage(languageKey: string) {
        this.languageService.use(languageKey);
    }

    collapseNavbar() {
        this.isNavbarCollapsed = true;
    }

    deconnexion() {
        this.collapseNavbar();
        this.authService.logoutByClick();
    }

    isAuthenticated() {
        return this.authenticate;
    }

    getNomPrenom() {
        if (!this.recupeNomPrenom && this.authenticate && this.nomPrenom.length <= 0) {
            this.authService.detectionActivite();
            this.principal.identity().pipe(take(1)).subscribe(id => {
                this.nomPrenom = id.prenom + ' ' + id.nom;
            });
        }
    }

    login() {
        this.modalRef = this.loginModalService.open();
    }

    logout() {
        this.authService.logoutByClick();
        this.router.navigate(['']);
    }

    toggleNavbar() {
        this.isNavbarCollapsed = !this.isNavbarCollapsed;
    }

    getImageUrl() {
        return this.isAuthenticated() ? this.principal.getImageUrl() : null;
    }

    connectionToFC(pageCallBack: string): void {
        window.open(this.authService.getAuthorizeFC(pageCallBack), '_self');
    }

    loopIndex(event: Event) {
        ((event.target as HTMLElement).closest('.dropdown-menu')!
            .querySelector('.nav-link') as HTMLElement)!.focus();
    }

    loopIndexNav() {
        if (window.innerWidth < 768) {
            this.firstNavLink.nativeElement.focus();
        }
    }

    checkAuthentification() {
        if (this.principal.isAuthenticated()) {
            this.authenticate = true;
            this.getNomPrenom();
            this.recupeNomPrenom = true;
        } else {
            this.authenticate = false;
        }
    }

    onMouseenter(drop: NgbDropdown) {
        drop.open();
    }

    onMouseleave(drop: NgbDropdown) {
        drop.close();
        this.dropAideEl.nativeElement.blur();
    }

    onClick(drop: NgbDropdown, ev: Event) {
        if (!drop.isOpen()) {
            this.dropAideEl.nativeElement.blur();
        }
    }
}
