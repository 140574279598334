import {RouterStateSnapshot, TitleStrategy} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {take, tap} from 'rxjs/operators';
import {forkJoin} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TemplatePageTitleStrategy extends TitleStrategy {
    constructor(
        private readonly title: Title,
        private translate: TranslateService,
    ) {
        super();
    }

    override updateTitle(routerState: RouterStateSnapshot) {
        const title = this.buildTitle(routerState);
        if (title) {
            forkJoin([
                this.translate.get(title),
                this.translate.get('global.complement-titre'),
            ])
            .pipe(
                take(1),
                tap({
                    next: titleArr => this.title.setTitle(`${titleArr[0]}${titleArr[1]}`),
                }),
            ).subscribe();
        }
    }
}
