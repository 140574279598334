import {Injectable, NgZone, Optional, SecurityContext} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';

export type NedAlertType = 'success' | 'danger' | 'warning' | 'info';

export interface NedAlert {
    id?: number;
    type: NedAlertType;
    msg: string;
    params?: any;
    timeout?: number;
    toast?: boolean;
    position?: string;
    scoped?: boolean;
    close?: (alerts: NedAlert[]) => void;
}

@Injectable({
    providedIn: 'root',
})
export class AlertService {
    private alertId: number;
    private alerts: NedAlert[];
    private timeout: number;
    private toast: boolean;
    private i18nEnabled: boolean;

    constructor(
        private sanitizer: DomSanitizer,
        private ngZone: NgZone,
        @Optional() private translateService: TranslateService,
    ) {
        this.toast = false;
        this.i18nEnabled = true;
        this.alertId = 0; // unique id for each alert. Starts from 0.
        this.alerts = [];
        this.timeout = 5000;
    }

    clear() {
        this.alerts.splice(0, this.alerts.length);
    }

    get(): NedAlert[] {
        return this.alerts;
    }

    success(msg: string, params?: any, position?: string): NedAlert {
        return this.addAlert(
            {
                type: 'success',
                msg,
                params,
                timeout: this.timeout,
                toast: this.isToast(),
                position,
            },
            [],
        );
    }

    error(msg: string = 'error.internalServer', params?: any, position?: string): NedAlert {
        return this.addAlert(
            {
                type: 'danger',
                msg,
                params,
                timeout: this.timeout,
                toast: this.isToast(),
                position,
            },
            [],
        );
    }

    warning(msg: string, params?: any, position?: string): NedAlert {
        return this.addAlert(
            {
                type: 'warning',
                msg,
                params,
                timeout: this.timeout,
                toast: this.isToast(),
                position,
            },
            [],
        );
    }

    info(msg: string, params?: any, position?: string): NedAlert {
        return this.addAlert(
            {
                type: 'info',
                msg,
                params,
                timeout: this.timeout,
                toast: this.isToast(),
                position,
            },
            [],
        );
    }

    addAlert(alertOptions: NedAlert, extAlerts: NedAlert[]): NedAlert {
        alertOptions.id = this.alertId++;
        if (this.i18nEnabled && alertOptions.msg) {
            alertOptions.msg = this.translateService.instant(alertOptions.msg, alertOptions.params);
        }
        const alert = this.factory(alertOptions);
        if (alertOptions.timeout && alertOptions.timeout > 0) {
            // Workaround protractor waiting for setTimeout.
            // Reference https://www.protractortest.org/#/timeouts
            this.ngZone.runOutsideAngular(() => {
                setTimeout(() => {
                    this.ngZone.run(() => {
                        this.closeAlert(alertOptions.id!, extAlerts);
                    });
                }, alertOptions.timeout);
            });
        }
        return alert;
    }

    closeAlert(id: number, extAlerts?: NedAlert[]): NedAlert[] {
        const thisAlerts: NedAlert[] = extAlerts && extAlerts.length > 0 ? extAlerts : this.alerts;
        return this.closeAlertByIndex(thisAlerts.map(e => e.id).indexOf(id), thisAlerts);
    }

    closeAlertByIndex(index: number, thisAlerts: NedAlert[]): NedAlert[] {
        return thisAlerts.splice(index, 1);
    }

    isToast(): boolean {
        return this.toast;
    }

    private factory(alertOptions: NedAlert): NedAlert {
        const alert: NedAlert = {
            type: alertOptions.type,
            msg: this.sanitizer.sanitize(SecurityContext.HTML, alertOptions.msg)!,
            id: alertOptions.id,
            timeout: alertOptions.timeout,
            toast: alertOptions.toast,
            position: alertOptions.position ? alertOptions.position : 'top right',
            scoped: alertOptions.scoped,
            close: (alerts: NedAlert[]) => {
                return this.closeAlert(alertOptions.id!, alerts);
            },
        };
        if (!alert.scoped) {
            this.alerts.push(alert);
        }
        return alert;
    }
}
