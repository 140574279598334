import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PermisVisite} from '@pv/models/permis-visite.model';
import {FormPermisVisite} from './models/form-permis-visite.model';
import {ConfigProvider} from '../blocks/config/config.provider';
import {ReferentielEtablissement} from './models/ReferentielEtablissement.model';

@Injectable({providedIn: 'root'})
export class PermisVisiteV2Service {
    idCarteDetenu: string | undefined;

    constructor(
        private http: HttpClient,
        private configProvider: ConfigProvider,
    ) {}

    /**
     * Méthode qui récupère la liste des permis de visite
     */
    getListePermisVisite(idCarteDetenu = '') {
        const url = this.configProvider.config.apiUrl + 'nedmasconsulterpermisvisite/api/v1/permisvisites';
        const params = new HttpParams()
            .set('idCarteDetenu', idCarteDetenu);

        return this.http.get<PermisVisite[]>(url, {params});
    }

    /**9
     * Méthode qui valide l'ajout d'un permis de visite
     */
    validerAjoutPermisVisite(permisVisiteForm: FormPermisVisite) {
        const headers = new HttpHeaders()
            .set('captchaId', permisVisiteForm.captchaId)
            .set('userEnteredCaptchaCode', permisVisiteForm.userEnteredCaptchaCode);
        const url = this.configProvider.config.apiUrl + 'nedmastraiterpermisvisite/api/v1/permisvisites';

        return this.http.post<PermisVisite>(url, permisVisiteForm, {headers});
    }

    supprimerPermisVisite(permisId: string) {
        const url = this.configProvider.config.apiUrl + 'nedmastraiterpermisvisite/api/v1/permis-visites/' + permisId;
        return this.http.delete(url);
    }

    /**
     * On vérifie si le permis de visite a planifié un rendez-vous parloir.
     * @param permisId l'id technique du permis visite (Genesis)
     * @returns boolean
     */
    existeRdvParloirParIdPermisVisite(permisId: string) {
        const url = this.configProvider.config.apiUrl + 'nedmasconsulterparloir/api/v1/rdvparloirs/permisvisites/' + permisId;
        return this.http.get(url);
    }

    scannerCodeBarrePermisVisite(codeBarres: string) {
        const url = this.configProvider.config.apiUrl + 'nedmasconsulterpermisvisite/api/v1/permisvisite/codebarres';
        const params = new HttpParams().set('codeBarres', codeBarres);
        return this.http.get<FormPermisVisite>(url, {params});
    }

    getIdCarteDetenu(): string | undefined {
        return this.idCarteDetenu;
    }

    setIdCarteDetenu(idCarteDetenu: string) {
        this.idCarteDetenu = idCarteDetenu;
    }

    // Recupere le libelle de l'etablissement à partir du code établissement
    getEtablissementByCodeEtablissement(codeEtablissement: string) {
        const url = this.configProvider.config.apiUrl + 'nedmasreferentiel/api/v1/etablissement/get-etablissement-by-code-etablissement/' + codeEtablissement;
        return this.http.get<ReferentielEtablissement>(url);
    }

}
