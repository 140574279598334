import {Injectable} from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root',
})
export class LocalService {

    key = '?+wi.&QNvAi[#e&~y7_,blb[[S)U3d';

    public saveData(key: string, value: string) {
        localStorage.setItem(key, this.encrypt(value));
    }

    public getData(key: string) {
        const data = localStorage.getItem(key);
        return data && this.decrypt(data) || '{}';
    }

    public removeData(key: string) {
        localStorage.removeItem(key);
    }

    public clearData() {
        localStorage.clear();
    }

    private encrypt(txt: string): string {
        return CryptoJS.AES.encrypt(txt, this.key).toString();
    }

    private decrypt(txtToDecrypt: string) {
        return CryptoJS.AES.decrypt(txtToDecrypt, this.key).toString(CryptoJS.enc.Utf8);
    }
}
