import {errorRoutes} from '@app/layouts/error/errorRoutes';
import {Route} from '@angular/router';
import {authentificationRoutes} from '@app/security/authentification.routes';
import {UserRouteAccessService} from '@app/core/auth/user-route-access-service';
import {FaqResolver} from '@app/communication/faq/faq.resolver';
import {EtablissementResolver} from '@app/communication/etablissement/etablissement.resolver';
import {
    PermisVisiteEnregistresResolver
} from '@pv/consulter/permis-visite-enregistres/permis-visite-enregistres.resolver';
import {PermisVisiteDemandesResolver} from '@pv/consulter/permis-visite-demandes/permis-visite-demandes.resolver';
import {DemandePermisVisiteConsulterResolver} from '@pv/consulter/demande-permis-visite-consulter.resolver';
import {
    InfosComplementairesVisiteurResolver
} from '@app/demande-permis-visite/saisir-infos-visiteur/page3-infos-complementaires-visiteur/infos-complementaires-visiteur.resolver';
import {
    InfosDetenuResolver
} from '@app/demande-permis-visite/saisir-infos-detenu/page2-infos-detenu/infos-detenu.resolver';
import {RdvParloirReserverResolver} from '@app/rdv-parloir/reserver/page1-reserver/rdv-parloir-reserver.resolver';
import {RdvParloirResumeResolver} from '@app/rdv-parloir/reserver/page2-resume/rdv-parloir-resume.resolver';
import {ConnexionResolver} from '@app/connexion/connexion.resolver';
import {AccueilFamilleResolver} from '@app/accueil/accueil-famille/accueil-famille.resolver';
import {ContactFormResolver} from '@app/layouts/footer/contact/contact.resolver';

export const APP_ROUTES: Route[] = [
    {
        path: '',
        loadChildren: () => import('./accueil/accueil.routes')
            .then(m => m.ACCUEIL_ROUTES),
        providers: [
            AccueilFamilleResolver,
        ],
    },
    {
        path: 'contact',
        loadChildren: () => import('./layouts/footer/contact/contact.routes')
            .then(m => m.CONTACT_ROUTES),
        providers: [
            ContactFormResolver,
        ]
    },
    {
        path: 'communication',
        loadChildren: () => import('./communication/communication.routes')
            .then(m => m.COMMUNICATION_ROUTES),
        providers: [
            FaqResolver,
            EtablissementResolver,
        ],
    },
    {
        path: 'permisvisiteV2',
        loadChildren: () => import('./permis-visite-2/permis-visite-2.route')
            .then(m => m.PERMISVISITE_ROUTES),
        providers: [
            PermisVisiteEnregistresResolver,
            PermisVisiteDemandesResolver,
            DemandePermisVisiteConsulterResolver,
        ],
        canActivate: [UserRouteAccessService],
    },
    {
        path: 'tunneldemandepermisvisite',
        loadChildren: () => import('./demande-permis-visite/demande-permis-visite.routes')
            .then(m => m.DEMANDE_PERMIS_VISITE_ROUTES),
        providers: [
            InfosDetenuResolver,
            InfosComplementairesVisiteurResolver,
        ],
        canActivate: [UserRouteAccessService],
    },
    {
        path: 'detail-permis-visite-demandes',
        loadChildren: () => import('./permis-visite-2/consulter/permis-visite-demandes/detail-permis-visite-demandes/detail-permis-visite-demandes.routes')
            .then(m => m.DETAIL_PERMIS_VISITE_DEMANDES_ROUTE),
    },
    {
        path: 'pecule',
        loadChildren: () => import('./pecule/pecule.route')
            .then(m => m.PECULE_ROUTES),
        canActivate: [UserRouteAccessService],
    },
    {
        path: 'parloir',
        loadChildren: () => import('./rdv-parloir/rdv-parloir.route')
            .then(m => m.rdvParloirRoutes),
        providers: [
            RdvParloirReserverResolver,
            RdvParloirResumeResolver,
        ],
        canActivate: [UserRouteAccessService],
    },
    {
        path: 'connexion',
        loadChildren: () => import('./connexion/connexion.route')
            .then(m => m.CONNEXION_ROUTES),
        providers: [
            ConnexionResolver,
        ],
    },
    {
        path: 'plan-du-site',
        loadChildren: () => import('./layouts/footer/plan-du-site/plan-du-site.routes')
            .then(m => m.PLAN_DU_SITE_ROUTES),
    },
    {
        path: 'accessibilite',
        loadChildren: () => import('./layouts/footer/accessibilite/accessibilite.routes')
            .then(m => m.ACCESSIBILITE_ROUTES),
    },
    {
        path: 'mentions-legales',
        loadChildren: () => import('./layouts/footer/mentions-legales/mentions.routes')
            .then(m => m.MENTIONS_LEGALES_ROUTES),
    },
    {
        path: 'tutoriel',
        loadChildren: () => import('./tutoriel/tutoriel.routes')
            .then(m => m.TUTORIEL_ROUTES),
        canActivate: [UserRouteAccessService],
    },
    {
        path: 'moncompte',
        loadChildren: () => import('./mon-compte/mon-compte.route')
            .then(m => m.MONCOMPTE_ROUTES),
        canActivate: [UserRouteAccessService],
    },
    ...authentificationRoutes,
    ...errorRoutes,
];
