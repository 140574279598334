import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class TutorielService {
    lectureVideoObservable: Observable<any>;
    private lectureVideo = new Subject<any>();

    constructor() {
        this.lectureVideoObservable = this.lectureVideo.asObservable();
    }

    isVideoLecture(lecture: boolean) {
        this.lectureVideo.next(lecture);
    }
}
