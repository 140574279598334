import {Component, Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ComposantPopinTwoChoicesSharedComponent} from './composant-popin-two-choices-shared.component';
import {TranslateService} from '@ngx-translate/core';

@Injectable({providedIn: 'root'})
export class ComposantPopInTwoChoicesSharedService {
    constructor(
        private modalService: NgbModal,
        private translateService: TranslateService,
    ) {}

    popIn(messagePopIn: string, libelleAccepter?: string, libelleRefuser?: string, titrePopIn?: string): any {
        const modalComponentInstance = this.modalService.open(ComposantPopinTwoChoicesSharedComponent as Component, {
            size: 'lg',
            backdrop: 'static',
            centered: true,
        });

        modalComponentInstance.componentInstance.titrePopIn = titrePopIn;
        modalComponentInstance.componentInstance.messagePopIn = messagePopIn;
        modalComponentInstance.componentInstance.libelleAccepter = libelleAccepter
            || this.translateService.instant('popIn.bouton.oui');
        modalComponentInstance.componentInstance.libelleRefuser = libelleRefuser
            || this.translateService.instant('popIn.bouton.non');
        return modalComponentInstance;
    }
}
