import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {MonCompteService} from '../mon-compte/mon-compte.service';
import {forkJoin, Observable, of} from 'rxjs';

@Injectable()
export class MonCompteResolver  {

    constructor(private monCompteService: MonCompteService) {
    }

    resolve(): Observable<any> {
        // On réalise les appels en fonction de l'activation du service Pécule.
        return forkJoin([
            this.monCompteService.getUtilisateurGP().pipe(catchError(() => of([]))),
            this.monCompteService.getIndicatifTelephone().pipe(catchError(() => of([]))),
        ]).pipe(map(result => {
            return {
                utilisateurGP: result[0],
                listeIndicatif: result[1],
            };
        }));
    }
}
