import {Component} from '@angular/core';

@Component({
    selector: 'ned-loader',
    templateUrl: './loader.component.html',
    standalone: true,
})
export class LoaderComponent {

    constructor() {
    }
}
