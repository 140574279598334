import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable()
export class TunnelService {
    public isTunnel = new BehaviorSubject<boolean>(false);
    public hasFooterForced = new BehaviorSubject<boolean>(false);

    constructor() {
    }

    showNavbar() {
        this.isTunnel.next(false);
    }

    hideNavbar() {
        setTimeout(() => this.isTunnel.next(true));
    }

    forceHasFooter() {
        this.hasFooterForced.next(true);
    }

    resetHasFooter() {
        this.hasFooterForced.next(false);
    }

    getTunnel(): Subject<boolean> {
        return this.isTunnel;
    }

    getHasFooterForced() {
        return this.hasFooterForced;
    }
}
