import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable()
export class LoadingService {
    public isLoading = new BehaviorSubject<boolean>(false);

    public loadingQueue: string[] = [];

    show() {
        this.isLoading.next(true);
    }

    hide() {
        this.isLoading.next(false);
    }

    getLoading(): Subject<boolean> {
        return this.isLoading;
    }

    /**
     * Méthode permettant de gérer l'ajout d'url à la queue
     * @param requeteUrl
     */
    pushInQueue(requeteUrl: string): void {
        // Ajout à la loading queue
        this.loadingQueue.push(requeteUrl);
        // Si la taille de la queue est de 1 on lance la rosace de chargement
        if (this.loadingQueue.length === 1) {
            this.show();
        }
    }

    /**
     * Méthode permettant de gérer le retrait des urls de la queue
     */
    popFromQueue(): void {
        // Retrait de la loading queue
        this.loadingQueue.pop();
        // S'il ne reste plus rien dans la loading queue on enlève la rosace de chargement
        if (this.loadingQueue.length === 0) {
            this.hide();
        }
    }
}
