<div class="container">
    <div class="c-card c-card-small mb-3 mt-40">
        <div class="c-card-small--body">
            <div class="d-flex justify-content-center flex-column">
                <h2 translate="error.title" class="custom subTitlePage mb-3"></h2>

                <div [hidden]="!errorMessage">
                    <span class="alert alert-danger">{{errorMessage}}</span>
                </div>
                <div [hidden]="!error403" class="alert alert-danger mt-3" translate="error.http.403"></div>
            </div>
        </div>
    </div>
</div>
