import {ConnexionService} from './connexion.service';
import {Injectable} from '@angular/core';

import {forkJoin, Observable} from 'rxjs';
import {MonCompteService} from '../mon-compte/mon-compte.service';
import {map} from 'rxjs/operators';

@Injectable()
export class ConnexionResolver  {

    constructor(
        private monCompteService: MonCompteService,
        private connexionService: ConnexionService,
    ) {
    }

    resolve(): Observable<any> {
        return forkJoin([
            this.monCompteService.getIndicatifTelephone(),
            this.connexionService.verifierPremiereConnexion(),
            this.monCompteService.getUtilisateurGP(),
        ]).pipe(map(result => {
            return {
                listeIndicatif: result[0],
                verifierConnexion: result[1],
                utilisateurGP: result[2],
            };
        }));
    }
}
