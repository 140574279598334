import {Injectable} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {AccountService} from '@app/core/auth/account.service';
import {AUTH_ID_TOKEN} from '@shared/constants/authentification.constants';
import {environment} from '@env/environment';
import {catchError, map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class Principal {
    private userIdentity: any;
    private authenticated = false;
    private authenticationState = new Subject<boolean>();

    constructor(
        private account: AccountService,
    ) {}

    authenticate(xXsrfToken: string) {
        this.authenticated = !!xXsrfToken;
        this.authenticationState.next(this.authenticated);
    }

    hasAnyAuthority(authorities: string[]) {
        return this.hasAnyAuthorityDirect(authorities);
    }

    hasAnyAuthorityDirect(authorities: string[]): boolean {
        if (!this.authenticated || !this.userIdentity || !this.userIdentity.authorities) {
            return false;
        }

        for (const val of authorities) {
            for (const value of this.userIdentity.authorities) {
                if (value.authority === val) {
                    return true;
                }
            }
        }

        return false;
    }

    identity() {
        // check and see if we have retrieved the userIdentity data from the server.
        // if we have, reuse it by immediately resolving
        if (this.userIdentity) {
            return of(this.userIdentity);
        }

        // retrieve the userIdentity data from the server, update the identity object, and then resolve.
        return this.account
            .get()
            .pipe(
                map(response => {
                    const account = response?.body;
                    if (account) {
                        this.userIdentity = account;
                        this.authenticated = true;
                    } else {
                        this.userIdentity = null;
                        this.authenticated = false;
                    }
                    this.authenticationState.next(this.userIdentity);
                    return this.userIdentity;
                }),
                catchError(err => {
                    if (!environment.production) {
                        console.log('Erreur d\'auth:', err);
                    }
                    this.userIdentity = null;
                    this.authenticated = false;
                    this.authenticationState.next(this.userIdentity);
                    return of(null);
                }),
            );
    }

    isAuthenticated(): boolean {
        return !!sessionStorage.getItem(AUTH_ID_TOKEN);
    }

    isIdentityResolved(): boolean {
        return this.userIdentity !== undefined;
    }

    isIdentityExisted(): boolean {
        return !!this.userIdentity;
    }

    getAuthenticationState(): Observable<any> {
        return this.authenticationState.asObservable();
    }

    validatePremiereConnexion() {
        this.userIdentity.premiereConnexion = false;
    }

    getImageUrl(): string {
        return this.isIdentityResolved() ? this.userIdentity.imageUrl : null;
    }
}
