import {RdvParloir} from './models/rdv-parloir.model';
import {createRequestOption} from '../shared';
import {PermisVisite} from './models/permis-visite.model';
import {CreneauxParloir} from './models/creneaux-parloir.model';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {ConfigProvider} from '../blocks/config/config.provider';
import {catchError} from 'rxjs/operators';
import {AlertService} from '@shared/service/alert.service';

@Injectable({providedIn: 'root'})
export class RdvParloirService {
    urlPermisVisiteValides = 'nedmasconsulterpermisvisite/api/v1/permisvisitevalides';
    urlVisiteurs = 'nedmasconsulterpermisvisite/api/v1/permisvisites/listevisiteurs';
    urlRdvParloir = 'nedmastraiterparloir/api/v1/rdvparloirs';
    urlTempsPresence = 'nedmasreferentiel/api/v1/parametres-etablissement/tempspresenceavantrdv';
    urlNbreJour = 'nedmasreferentiel/api/v1/parametres-parloir/nombrejour';
    urlNbreHeure = 'nedmasreferentiel/api/v1/parametres-parloir/nombreheure';
    urlValidMajParloir = 'nedmasconsulterparloir/api/v1/validerMajParloirs';

    constructor(
        private http: HttpClient,
        private configProvider: ConfigProvider,
        private alertService: AlertService,
    ) {
    }

    getPermisVisite(idCarteDetenu?: string) {
        const params = new HttpParams()
            .set('idCarteDetenu', idCarteDetenu || '');
        return this.http.get<PermisVisite[]>(this.configProvider.config.apiUrl + this.urlPermisVisiteValides, {params});
    }

    getVisiteurs(
        numeroEcrou: number,
        numeroPermisVisite: string,
        codeEtablissement: string,
        dateDemande: string,
        idCreneauParloir: number,
        idPermisVisitePrincipal: number,
    ) {
        const url = this.configProvider.config.apiUrl + this.urlVisiteurs;
        return this.http.post<any>(url, {
            numeroEcrou: numeroEcrou.toString(),
            numeroPermisVisite,
            codeEtablissement,
            dateDemande,
            idCreneauParloir: idCreneauParloir.toString(),
            idPermisVisitePrincipal: idPermisVisitePrincipal.toString(),
        });
    }

    getCreneauxParloir(idPermisVisite: number, req?: any): Observable<HttpResponse<CreneauxParloir[]>> {
        const options = createRequestOption(req);
        const url = this.configProvider.config.apiUrl + 'nedmasconsulterparloir/api/v1/creneauxparloirs/' + idPermisVisite;
        return this.http.get<CreneauxParloir[]>(url, {
            params: options,
            observe: 'response',
        });
    }

    /**
     * Vérifie la disponibilité de la date demandée par le visiteur, et du nombre de personne présent sur ce créneau.
     */
    verifierDateEtPermisVisite(req?: any): Observable<HttpResponse<any>> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
        const url = this.configProvider.config.apiUrl + 'nedmasconsulterparloir/api/v1/creneauxparloirs/verifier';
        return this.http.post<any>(url, req, httpOptions);
    }

    getListeRdvParloir(rdvAnterieurs = false, page: number) {
        const headers = new HttpHeaders();
        const params = new HttpParams()
            .set('page', page.toString())
            .set('size', '10')
            .set('rdvAnterieurs', rdvAnterieurs ? 'true' : 'false');
        const url = this.configProvider.config.apiUrl + 'nedmasconsulterparloir/api/v1/rdvparloirs';
        return this.http.get<RdvParloir[]>(url, {headers, params, observe: 'response'})
            .pipe(catchError(err => {
                this.alertService.error('error.internalServerError');

                return of(null);
            }));
    }

    deleteRdvParloir(rdvParloirASupprimer: RdvParloir) {

        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: rdvParloirASupprimer,
        };

        const url =
            this.configProvider.config.apiUrl + 'nedmastraiterparloir/api/v1/rdvparloirs';
        return this.http.delete(url, options);
    }

    postRdvParloir(rdvParloir: RdvParloir) {
        return this.http.post(this.configProvider.config.apiUrl + this.urlRdvParloir, rdvParloir);
    }

    modifierRdvParloir(rdvParloir: RdvParloir) {
        return this.http.put(this.configProvider.config.apiUrl + this.urlRdvParloir, rdvParloir);
    }

    /**
     * Récupère le temps de présence du visiteur avant un Rdv parloir.
     * @param codeEtablissement
     */
    getTempsPresenceAvantRdv(codeEtablissement: string) {
        const headers = new HttpHeaders();
        const params = new HttpParams()
            .set('codeEtablissement', codeEtablissement);
        const url = this.configProvider.config.apiUrl + this.urlTempsPresence;
        return this.http.get<RdvParloir[]>(url, {headers, params});

    }

    /**
     * Récupère le nombre de jour pour bloquer la modification d'un rdv parloir.
     * @param codeEtablissement
     */
    getNombreJourPourModiferRdvParloir(codeEtablissement: string) {
        const headers = new HttpHeaders();
        const params = new HttpParams()
            .set('codeEtablissement', codeEtablissement);
        const url = this.configProvider.config.apiUrl + this.urlNbreJour;
        return this.http.get<number>(url, {headers, params});

    }

    /**
     * Récupère le nombre d'heure pour bloquer la modification d'un rdv parloir.
     * @param codeEtablissement
     */
    getNombreHeurePourModiferRdvParloir(codeEtablissement: string) {
        const headers = new HttpHeaders();
        const params = new HttpParams()
            .set('codeEtablissement', codeEtablissement);
        const url = this.configProvider.config.apiUrl + this.urlNbreHeure;
        return this.http.get<number>(url, {headers, params});

    }

    /**
     * Valide la possibilité de modifier le rdv en fonction du paramétrage de l'établissement
     * @param nbreHeure
     * @param nbreJour
     * @param dateRdv
     */
    validMajParloir(nbreHeure: number, nbreJour: number, dateRdv: Date) {
        const url = this.configProvider.config.apiUrl + this.urlValidMajParloir + '/' + nbreHeure + '/' + nbreJour
            + '/' + dateRdv;
        return this.http.get<boolean>(url, {observe: 'response'});
    }

}
