import {Routes} from '@angular/router';

import {ErrorComponent} from '@app/layouts/error/error.component';

export const errorRoutes: Routes = [
    {
        path: 'error',
        component: ErrorComponent,
        title: 'error.title',
    },
    {
        path: 'accessdenied',
        component: ErrorComponent,
        data: {
            error403: true,
        },
        title: 'error.title',
    },
];
