import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {CommunicationService} from '../communication.service';
import {catchError} from 'rxjs/operators';

@Injectable()
export class FaqResolver  {
    constructor(private http: HttpClient, private communicationService: CommunicationService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.communicationService.getFaq().pipe(
            catchError(err => {
                return of(null);
            }),
        );
    }
}
