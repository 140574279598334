import {IStatutInfoDetenu} from './statut-info-detenu.model';
import {Tribunal} from './tribunaux.model';

export interface IInformationDetenu {
    id?: number;
    nom?: string;
    prenom?: string;
    numero?: number;
    codeEtablissement?: string;
    tribunal?: Tribunal;
    numeroAffaire?: string;
    demandePermisVisiteId?: number;
    statutInfoDetenu?: IStatutInfoDetenu;
}

export class InformationDetenu implements IInformationDetenu {
    constructor(
        public id?: number,
        public nom?: string,
        public prenom?: string,
        public numero?: number,
        public codeEtablissement?: string,
        public tribunal?: Tribunal,
        public numeroAffaire?: string,
        public demandePermisVisiteId?: number,
        public statutInfoDetenu?: IStatutInfoDetenu,
    ) {
        id && (this.id = id);
        nom && (this.nom = nom);
        prenom && (this.prenom = prenom);
        numero && (this.numero = numero);
        codeEtablissement && (this.codeEtablissement = codeEtablissement);
        tribunal && (this.tribunal = tribunal);
        numeroAffaire && (this.numeroAffaire = numeroAffaire);
        demandePermisVisiteId && (this.demandePermisVisiteId = demandePermisVisiteId);
        statutInfoDetenu && (this.statutInfoDetenu = statutInfoDetenu);
    }
}

export function getInformationDetenuIdentifier(informationDetenu: IInformationDetenu): number | undefined {
    return informationDetenu.id;
}
