import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigProvider} from '@app/blocks/config/config.provider';
import {UtilisateurConnecte} from '../models/utilisateur-connecte.model';

@Injectable({providedIn: 'root'})
export class CarteDetenuService {

    urlGetCarteDetenus = 'nedmasutilisateurfamille/api/v1/carte-detenus/famille';

    constructor(
        private http: HttpClient,
        private configProvider: ConfigProvider,
    ) {}

    getAllCarteDetenus() {
        const url = this.configProvider.config.apiUrl + this.urlGetCarteDetenus;
        return this.http.get<UtilisateurConnecte>(url);
    }
}
