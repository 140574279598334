import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class Data {
    private _storage: any;

    get storage() {
        return this._storage !== undefined && JSON.parse(this._storage) || undefined;
    }

    set storage(data: any) {
        if (data !== undefined) {
            data = JSON.stringify(data);
        }
        this._storage = data;
    }
}
