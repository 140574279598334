import {APP_INITIALIZER, enableProdMode, importProvidersFrom, inject, LOCALE_ID} from '@angular/core';
import {environment} from '@env/environment';
import {NedMainComponent} from '@app/layouts/main/main.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NedTranslateService} from '@shared/service/ned.translate.service';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {AuthInterceptor} from '@app/blocks/interceptor/auth.interceptor';
import {NotificationInterceptor} from '@app/blocks/interceptor/notification.interceptor';
import {
    PreloadAllModules,
    provideRouter,
    TitleStrategy,
    withInMemoryScrolling, withPreloading,
    withRouterConfig
} from '@angular/router';
import {ErrorHandlerInterceptor} from '@app/blocks/interceptor/errorhandler.interceptor';
import {Config} from '@app/blocks/config/config.model';
import {HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {ConfigProvider} from '@app/blocks/config/config.provider';
import {Globals} from '@shared/util/globals';
import {LocationStrategy, PathLocationStrategy, registerLocaleData, TitleCasePipe} from '@angular/common';
import {FindLanguageFromKeyPipe} from '@app/shared';
import {bootstrapApplication} from '@angular/platform-browser';
import localeFr from '@angular/common/locales/fr';
import {LoadingService} from '@shared/loading-interceptor/loading.service';
import {TunnelService} from '@shared/tunnel-interceptor/tunnel.service';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TemplatePageTitleStrategy} from '@shared/page-title/page-title';
import {PageAccueilResolver} from '@app/pecule/pages/page-accueil/page-accueil.resolver';
import {MonCompteResolver} from '@app/mon-compte/mon-compte.resolver';
import {APP_ROUTES} from '@app/app.route';
import {LoadingInterceptor} from '@shared/loading-interceptor/loading-interceptor';
import {InfiniteScrollDirective} from 'ngx-infinite-scroll';
import {Data} from '@shared/util/data.model';
import {DatefrPipe} from '@shared/pipe/datefr.pipe';
import {AlerteFamilleService} from '@app/alerte-famille/alerte-famille.service';

registerLocaleData(localeFr);


if (environment.production) {
    enableProdMode();
}

bootstrapApplication(NedMainComponent, {
    providers: [
        importProvidersFrom(
            TranslateModule.forRoot({
                defaultLanguage: 'fr',
                loader: {
                    provide: TranslateLoader,
                    useFactory: (http: HttpClient): any =>
                        new TranslateHttpLoader(http, '/i18n/messages.', '.json'),
                    deps: [HttpClient],
                },
            }),
            NgbModule,
            InfiniteScrollDirective,
        ),
        {
            provide: LOCALE_ID,
            useValue: 'fr',
        },
        PageAccueilResolver,
        MonCompteResolver,
        FindLanguageFromKeyPipe,
        LoadingService,
        TunnelService,
        AlerteFamilleService,
        Data,
        DatefrPipe,
        TitleCasePipe,
        Globals,
        {
            provide: APP_INITIALIZER,
            useFactory: () => {
                const configProvider = inject(ConfigProvider);
                const httpClient = inject(HttpClient);
                return () => new Promise((resolve) => {
                    httpClient.get<Config>('/content/config.json').subscribe({
                        next: config => {
                            configProvider.set(config);
                            resolve(true);
                        },
                        error: () => {
                            resolve(false);
                        },
                    });
                });
            },
            multi: true,
        },
        {
            provide: TitleStrategy,
            useClass: TemplatePageTitleStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NotificationInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
            deps: [ConfigProvider],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true,
        },
        {provide: LocationStrategy, useClass: PathLocationStrategy},
        {provide: TranslateService, useClass: NedTranslateService},
        provideHttpClient(withInterceptorsFromDi()),
        provideRouter(APP_ROUTES,
            withInMemoryScrolling({
                scrollPositionRestoration: 'enabled',
                anchorScrolling: 'enabled'
            }),
            withPreloading(PreloadAllModules),
            // withDebugTracing(),
            withRouterConfig({paramsInheritanceStrategy: 'always'}),
        )]
})
    .catch(err => console.error(err));
