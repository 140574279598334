import {VerifierConnexion} from './models/verifier-connexion.model';
import {UtilisateurFamille} from './models/utilisateur-famille.model';
import {HttpClient} from '@angular/common/http';
import {ConfigProvider} from '../blocks/config/config.provider';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';

@Injectable({
    providedIn: 'root',
})
export class ConnexionService {

    constructor(private http: HttpClient, private configProvider: ConfigProvider) { }

    /**
     * Met à jour les informations de l'utilisateur après son parcours de première connexion
     * @param utilisateur les informations de l'utilisateur pour mise à jour
     */
    updateUtilisateurFamille(utilisateur: UtilisateurFamille) {
        return this.http.put<UtilisateurFamille>(this.configProvider.config.apiUrl + 'nedmasutilisateurfamille/api/v1/utilisateur', utilisateur);
    }

    /**
     * Vérifie si l'utilisateur a déjà réalisé son parcours de première connexion
     */
    verifierPremiereConnexion(): Observable<any> {
        return this.http.get<VerifierConnexion>(this.configProvider.config.apiUrl + 'nedmasutilisateurfamille/api/v1/verifier/connexion');
    }
}
