import {AfterViewInit, Component, Renderer2} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'ned-login-modal',
    templateUrl: './login.component.html',
    standalone: true,
    imports: [
        TranslateModule,
        NgIf,
        FormsModule,
    ],
})
export class LoginModalComponent implements AfterViewInit {
    authenticationError: boolean | undefined;
    password: string | undefined;
    rememberMe: boolean | undefined;
    username: string | undefined;
    credentials: any;

    constructor(
        private renderer: Renderer2,
        private router: Router,
        public activeModal: NgbActiveModal,
    ) {
        this.credentials = {};
    }

    ngAfterViewInit() {
        setTimeout(() => this.renderer.selectRootElement('#username').focus());
    }

    cancel() {
        this.credentials = {
            username: null,
            password: null,
            rememberMe: true,
        };
        this.authenticationError = false;
        this.activeModal.dismiss('cancel');
    }

    login() {
        /*
        this.loginService
            .login({
                username: this.username,
                password: this.password,
                rememberMe: this.rememberMe
            })
            .then(() => {
                this.authenticationError = false;
                this.activeModal.dismiss('login success');
                if (this.router.url === '/register' || /^\/activate\//.test(this.router.url) || /^\/reset\//.test(this.router.url)) {
                    this.router.navigate(['']);
                }

                this.eventManager.broadcast({
                    name: 'authenticationSuccess',
                    content: 'Sending Authentication Success'
                });

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is succesful, go to stored previousState and clear previousState
                const redirect = this.stateStorageService.getUrl();
                if (redirect) {
                    this.stateStorageService.storeUrl(null);
                    this.router.navigate([redirect]);
                }
            })
            .catch(() => {
                this.authenticationError = true;
            });
        */
    }

    register() {
        this.activeModal.dismiss('to state register');
        this.router.navigate(['/register']).then();
    }

    requestResetPassword() {
        this.activeModal.dismiss('to state requestReset');
        this.router.navigate(['/reset', 'request']).then();
    }
}
