import {Injectable} from '@angular/core';
import {Config} from './config.model';

@Injectable({providedIn: 'root'})
export class ConfigProvider {
    private _config!: Config;

    get config() {
        return this._config;
    }

    set(config: Config) {
        this._config = config;
    }
}
