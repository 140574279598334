import {Injectable} from '@angular/core';
import {ConfigProvider} from '@app/blocks/config/config.provider';
import {DemandePermisVisite, IDemandePermisVisite} from '../models/demande-permis-visite.model';
import {InformationVisiteur} from '../models/information-visiteur.model';
import {StatutDemande} from '../models/enumerations/statut-demande.model';
import {ICreationPjDemandePermisVisite} from '../models/creation-pj-demande-permis-visite.model';
import {TransmissionNouvelleDemandePermisVisite} from '../models/transmission-nouvelle-demande-permis-visite.model';
import {TransmissionDemandePermisVisiteCorrigee} from '../models/transmission-demande-permis-visite-corrigee.model';
import {catchError} from 'rxjs/operators';
import {AlertService} from '@shared/service/alert.service';
import {IChoixBeneficiaire} from '../models/choix-beneficiaire.model';
import {IInfosVisiteur} from '../models/infos-visiteur.model';
import {IInfosComplementairesVisiteur} from '../models/infos-complementaires-visiteur.model';
import {IDemandeMineurEmancipe} from '../models/demande-mineur-emancipe.model';
import {InfosMajeurRattache} from '../models/infos-majeur-rattache.model';
import {ILienVisiteurDetenu} from '../models/lien-visiteur-detenu.model';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {MajDemandePermisVisiteSortiParcours} from '../models/maj-demande-permis-visite-sorti-parcours.model';
import {TypePj} from '../models/enumerations/type-pj.model';
import {LocalService} from '@shared/storage/local.service';

export type EntityResponseType = HttpResponse<IDemandePermisVisite>;
export type EntityArrayResponseType = HttpResponse<IDemandePermisVisite[]>;

@Injectable({providedIn: 'root'})
export class DemandePermisVisiteService {

    public resourceUrl = 'api/v1/demande-permis-visites';
    public consultationUrl = this.configProvider.config.apiUrl + 'nedmasconsulterpermisvisite/' + this.resourceUrl;
    public traitementUrl = this.configProvider.config.apiUrl + 'nedmastraiterpermisvisite/' + this.resourceUrl;
    public supprimerDpvBrouillonUrl = this.traitementUrl + '/famille/brouillon/suppression';

    private moduletraiterPv = 'nedmastraiterpermisvisite';
    public urlCreationPj = this.configProvider.config.apiUrl + this.moduletraiterPv +
        '/api/v1/demande-permis-visites/famille/creationPieceJustificative';
    public urlTransmissionNouvelleDpv = this.configProvider.config.apiUrl + this.moduletraiterPv +
        '/api/v1/demande-permis-visites/famille/transmissionNouvelleDemandePermisVisite';
    public urlTransmissionDpvCorrigee = this.configProvider.config.apiUrl + this.moduletraiterPv +
        '/api/v1/demande-permis-visites/famille/transmissionDemandePermisVisiteCorrigee';
    public urlCreateChoixBeneficiaire = this.configProvider.config.apiUrl + this.moduletraiterPv +
        '/api/v1/demande-permis-visites/famille/createDemandePermisVisiteChoixBeneficiaire';
    public urlUpdateChoixBeneficiaire = this.configProvider.config.apiUrl + this.moduletraiterPv +
        '/api/v1/demande-permis-visites/famille/updateDemandePermisVisiteChoixBeneficiaire';
    public urlCreateInfosVisiteur = this.configProvider.config.apiUrl + this.moduletraiterPv +
        '/api/v1/demande-permis-visites/famille/createDemandePermisVisiteInfosVisiteur';
    public urlUpdateInfosVisiteur = this.configProvider.config.apiUrl + this.moduletraiterPv +
        '/api/v1/demande-permis-visites/famille/updateDemandePermisVisiteInfosVisiteur';
    public urlUpdateInfosComplementairesVisiteur = this.configProvider.config.apiUrl + this.moduletraiterPv +
        '/api/v1/demande-permis-visites/famille/updateDemandePermisVisiteInfosComplementairesVisiteur';
    public urlUpdateDemandeMineurEmancipe = this.configProvider.config.apiUrl + this.moduletraiterPv +
        '/api/v1/demande-permis-visites/famille/updateDemandePermisVisiteDemandeMineurEmancipe';
    public urlUpdateInfosMajeurRettache = this.configProvider.config.apiUrl + this.moduletraiterPv +
        '/api/v1/demande-permis-visites/famille/updateDemandePermisVisiteInfosMajeurRattache';
    public urlUpdateLienVisiteurDetenu = this.configProvider.config.apiUrl + this.moduletraiterPv +
        '/api/v1/demande-permis-visites/famille/updateDemandePermisVisiteLienVisiteurDetenu';
    public urlDeletePjLienVisiteurDetenu = this.configProvider.config.apiUrl + this.moduletraiterPv +
        '/api/v1/demande-permis-visites/famille/deleteDemandePermisVisitePjLienVisiteurDetenu';
    public urlRazDpvModifiee = this.configProvider.config.apiUrl + this.moduletraiterPv +
        '/api/v1/demande-permis-visites/famille/razDpvEstModifiee';
    public urlMajSortiPopin = this.configProvider.config.apiUrl + this.moduletraiterPv +
        '/api/v1/demande-permis-visites/famille/majSortiPopin';

    private _demande: DemandePermisVisite | null = null;
    private _demandeBddPourRollBack: DemandePermisVisite | null = null;
    private _demandeIncompleteEnCoursDeModification: DemandePermisVisite | null = null;
    private _demandeBrouillonEnCoursDeReprise = false;
    private _demandeModifieeDurantSession = false;
    private _demandeIncomplete = false;
    // Booleen permettant de savoir si c'est la 1ere fois que l'on vient sur l'écran Infos complementaires visiteur ou non
    private _isNotFirstTimeInfoComplVisiteur = false;
    // Booleen permettant de savoir si c'est la 1ere fois que l'on vient sur l'écran Infos visiteur ou non
    private _isNotFirstTimeInfoVisiteur = false;
    private _statut: StatutDemande | undefined;

    constructor(
        protected http: HttpClient,
        private configProvider: ConfigProvider,
        private alertService: AlertService,
        private localService: LocalService,
    ) {
    }

    createDemandePermisVisiteChoixBeneficiaire(choixBeneficiaire: IChoixBeneficiaire): Observable<EntityResponseType> {
        return this.http
            .post<IDemandePermisVisite>(this.urlCreateChoixBeneficiaire, choixBeneficiaire, {observe: 'response'});
    }

    updateDemandePermisVisiteChoixBeneficiaire(choixBeneficiaire: IChoixBeneficiaire): Observable<EntityResponseType> {
        return this.http
            .put<IDemandePermisVisite>(this.urlUpdateChoixBeneficiaire, choixBeneficiaire, {observe: 'response'});
    }

    createDemandePermisVisiteInfosVisiteur(infosVisiteur: IInfosVisiteur): Observable<EntityResponseType> {
        return this.http
            .post<IDemandePermisVisite>(this.urlCreateInfosVisiteur, infosVisiteur, {observe: 'response'});
    }

    updateDemandePermisVisiteInfosVisiteur(infosVisiteur: IInfosVisiteur): Observable<EntityResponseType> {
        return this.http
            .put<IDemandePermisVisite>(this.urlUpdateInfosVisiteur, infosVisiteur, {observe: 'response'});
    }

    updateDemandePermisVisiteInfosComplementairesVisiteur(infosComplementairesVisiteur: IInfosComplementairesVisiteur): Observable<EntityResponseType> {
        return this.http
            .put<IDemandePermisVisite>(this.urlUpdateInfosComplementairesVisiteur, infosComplementairesVisiteur, {observe: 'response'});
    }

    updateDemandePermisVisiteDemandeMineurEmancipe(demandeMineurEmancipe: IDemandeMineurEmancipe): Observable<EntityResponseType> {
        return this.http
            .put<IDemandePermisVisite>(this.urlUpdateDemandeMineurEmancipe, demandeMineurEmancipe, {observe: 'response'});
    }

    updateDemandePermisVisiteInfosMajeurRattache(infosMajeurRattache: InfosMajeurRattache): Observable<EntityResponseType> {
        return this.http
            .put<IDemandePermisVisite>(this.urlUpdateInfosMajeurRettache, infosMajeurRattache, {observe: 'response'});
    }

    updateDemandePermisVisiteLienVisiteurDetenu(lienVisiteurDetenu: ILienVisiteurDetenu): Observable<EntityResponseType> {
        return this.http
            .put<IDemandePermisVisite>(this.urlUpdateLienVisiteurDetenu, lienVisiteurDetenu, {observe: 'response'});
    }

    deleteDemandePermisVisitePJLienVisiteurDetenu(idDpv: number): Observable<EntityResponseType> {
        return this.http
            .put<IDemandePermisVisite>(this.urlDeletePjLienVisiteurDetenu + '/' + idDpv, {}, {observe: 'response'});
    }

    creationPjEnBddNedEtGed(creationPjDemandePermisVisite: ICreationPjDemandePermisVisite): Observable<HttpResponse<DemandePermisVisite>> {
        return this.http
            .post<IDemandePermisVisite>(this.urlCreationPj, creationPjDemandePermisVisite, {observe: 'response'});
    }

    transmissionNouvelleDemandePermisVisite(transmissionNouvelleDemandePermisVisite: TransmissionNouvelleDemandePermisVisite): Observable<HttpResponse<IDemandePermisVisite>> {
        return this.http
            .put<IDemandePermisVisite>(this.urlTransmissionNouvelleDpv, transmissionNouvelleDemandePermisVisite, {observe: 'response'});
    }

    transmettreDemandePermisVisiteCorrigee(transmissionDemandePermisVisiteCorrigee: TransmissionDemandePermisVisiteCorrigee): Observable<HttpResponse<IDemandePermisVisite>> {
        return this.http
            .put<IDemandePermisVisite>(this.urlTransmissionDpvCorrigee, transmissionDemandePermisVisiteCorrigee, {observe: 'response'});
    }

    delete(id: number): Observable<HttpResponse<{}>> {
        return this.http.delete(`${this.supprimerDpvBrouillonUrl}/${id}`, {observe: 'response'});
    }

    getDemande(): IDemandePermisVisite | undefined {
        if (!Object.keys(this._demande || {}).length) {
            return undefined;
        }
        return this._demande || JSON.parse(this.localService.getData('objectDemande')) || undefined;
    }

    setDemande(dpv: IDemandePermisVisite) {
        if (dpv.pieceJustificativeComplementaires && dpv.pieceJustificativeComplementaires.length) {
            dpv.pieceJustificativeComplementaires = dpv.pieceJustificativeComplementaires
                .sort((a, b) => (a.id || 0) - (b.id || 0))
                .map((pj, i) => {
                    // @ts-ignore
                    pj.type = TypePj[`COMPLEMENTAIRE${i + 1}`];
                    return pj;
                });
        }

        this._demande = dpv;
        try {
            this.localService.saveData('objetDemande', JSON.stringify(dpv));
        } catch (e) {
            console.error(e);
        }
    }

    getDemandeBrouillonEnCoursDeReprise(): boolean {
        return this._demandeBrouillonEnCoursDeReprise;
    }

    setDemandeBrouillonEnCoursDeReprise(value: boolean) {
        this._demandeBrouillonEnCoursDeReprise = value;
    }

    getDemandeIncompleteEnCoursDeModification(): IDemandePermisVisite | null {
        return this._demandeIncompleteEnCoursDeModification;
    }

    setDemandeIncompleteEnCoursDeModification(value: IDemandePermisVisite) {
        this._demandeIncompleteEnCoursDeModification = value;
    }

    getDemandeIncomplete(): boolean {
        return this._demandeIncomplete;
    }

    setDemandeIncomplete(value: boolean) {
        this._demandeIncomplete = value;
    }

    getDemandeModifieeDurantSession(): boolean {
        return this._demandeModifieeDurantSession;
    }

    setDemandeModifieeDurantSession(value: boolean) {
        this._demandeModifieeDurantSession = value;
    }

    /**
     * Méthode permettant d'avoir accès à la demande
     * dans l'état initial de reprise
     */
    getDemandeBddPourRollBack(): IDemandePermisVisite | null {
        return this._demandeBddPourRollBack;
    }

    getIsNotFirstTimeInfoComplVisiteur(): boolean {
        return this._isNotFirstTimeInfoComplVisiteur;
    }

    setIsNotFirstTimeInfoComplVisiteur(value: boolean) {
        this._isNotFirstTimeInfoComplVisiteur = value;
    }

    getIsNotFirstTimeInfoVisiteur(): boolean {
        return this._isNotFirstTimeInfoVisiteur;
    }

    setIsNotFirstTimeInfoVisiteur(value: boolean) {
        this._isNotFirstTimeInfoVisiteur = value;
    }

    /**
     * M?thode permettant de cr?er une demande dans l'?tat initial
     * auquel elle est reprise
     * @param value demandePermisVisite ? partir duquel est construite la demande rollback
     */
    createDemandePermisVisiteRollback(value: IDemandePermisVisite) {
        if (null === this._demandeBddPourRollBack) {
            const infosVisiteur: InformationVisiteur = {...value.informationVisiteur};
            const infosDetenu: InformationVisiteur = {...value.informationDetenu};
            this._demandeBddPourRollBack = {...value};
            this._demandeBddPourRollBack.informationDetenu = infosDetenu;
            this._demandeBddPourRollBack.informationVisiteur = infosVisiteur;
        }
    }

    createDemandeIncompleteEnCoursDeModification(value: IDemandePermisVisite) {
        if (null === this._demandeIncompleteEnCoursDeModification) {
            const infosVisiteur: InformationVisiteur = {...value.informationVisiteur};
            const infosDetenu: InformationVisiteur = {...value.informationDetenu};
            this._demandeIncompleteEnCoursDeModification = {...value};
            this._demandeIncompleteEnCoursDeModification.informationDetenu = infosDetenu;
            this._demandeIncompleteEnCoursDeModification.informationVisiteur = infosVisiteur;
        }
    }

    clearDemande() {
        this._demande = null;
        this._demandeBrouillonEnCoursDeReprise = false;
        this._demandeModifieeDurantSession = false;
        this._statut = undefined;
    }

    /**
     * Permet d'effacer la demande rollback
     */
    clearDemandeBddPourRollBack() {
        this._demandeBddPourRollBack = null;
    }

    clearDemandeIncompleteEnCoursDeModification() {
        this._demandeIncompleteEnCoursDeModification = null;
    }

    annulerDPV(idDPV: string | undefined) {
        const headers = new HttpHeaders();
        return this.http.put(`${this.traitementUrl}/famille/abandonDPV/${idDPV}`, null,
                {headers, observe: 'response'})
            .pipe(catchError(_ => {
                this.alertService.error('error.internalServer');
                return of(new HttpResponse({status: 500}));
            }));
    }

    getAllDemandeByUser() {
        return this.http
            .get<IDemandePermisVisite[]>(`${this.consultationUrl}/liste-dpv-famille`, {});
    }

    verifierExistenceDemandePermisVisite(idUser: number, numeroEcrou: number): Observable<HttpResponse<boolean>> {
        return this.http
            .get<boolean>(`${this.consultationUrl}/existenceDemandeActive/${idUser}/${numeroEcrou}`, {observe: 'response'});
    }

    recupererListPvActifs(numeroEcrou: number, codeEtablissement: string, nomVisiteur: string,
                          prenomVisiteur: string, dateNaissanceVisiteur: Date): Observable<HttpResponse<boolean>> {
        return this.http
            .get<boolean>(`${this.consultationUrl}/existencePvGenesis/${numeroEcrou}/${codeEtablissement}/${nomVisiteur}/${prenomVisiteur}/${dateNaissanceVisiteur}`,
                {observe: 'response'});
    }

    verifierPvActifsMajeur(numeroPv: string | null | undefined, codeEtablissement: string | null | undefined, numeroEcrou: number | null | undefined): Observable<HttpResponse<boolean>> {
        return this.http
            .get<boolean>(`${this.consultationUrl}/existencePvMajeurGenesis/${numeroPv}/${codeEtablissement}/${numeroEcrou}`, {observe: 'response'});
    }

    verifierExistenceDemandePermisVisiteNomPrenomUtilisateur(numero: number, nom: string, prenom: string, dateNaissance: string, idDpv: number): Observable<HttpResponse<boolean>> {
        return this.http
            .get<boolean>(`${this.consultationUrl}/existenceDemandeActiveNomPrenom/${numero}/${nom}/${prenom}/${dateNaissance}/${idDpv}`, {observe: 'response'});
    }

    /**
     * Méthode permettant de flaguer la demande si elle est modifiée
     * durant le passage dans le tunnel
     */
    modificationEffectuee() {
        this._demandeModifieeDurantSession = true;
    }

    isDemandeNonNull(): boolean {
        return !!this._demande;
    }

    // Recupération de la demande avec la liste de PJ trié selon l'ordre donnée
    recuperDemandeCompleteParId(id: number): Observable<IDemandePermisVisite> {
        return this.http.get<IDemandePermisVisite>(`${this.consultationUrl}/demande-complete-famille/${id}`, {});
    }

    // Stock l'état du statut de la demande avant qu'il passe a TRANSMIS à la fin du tunnel
    getStatut(): StatutDemande | undefined {
        return this._statut;
    }

    setStatut(value: StatutDemande) {
        this._statut = value;
    }

    updateTemoinLeveeEcrouByIdAndLibelle(idDpv: number, libelleTemoin: string): Observable<EntityResponseType> {
        return this.http.put<any>(`${this.traitementUrl}/updateTemoinLeveeEcrou/${idDpv}/${libelleTemoin}`, '');
    }

    majDemandeSuiteLeveeEcrou(demande: DemandePermisVisite): Observable<EntityResponseType> {
        return this.http.put<any>(`${this.traitementUrl}/majDemandeSuiteLeveeEcrou`, demande);
    }

    razDpvModifiee(idDpv: number): Observable<EntityResponseType> {
        return this.http.put<any>(`${this.urlRazDpvModifiee}/${idDpv}`, '');
    }

    majSortiPopin(majDemandePermisVisiteSortiParcours: MajDemandePermisVisiteSortiParcours): Observable<EntityResponseType> {
        return this.http.put<any>(this.urlMajSortiPopin, majDemandePermisVisiteSortiParcours);
    }
}
