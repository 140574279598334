import {AlertService} from '@shared/service/alert.service';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Principal} from '@app/core/auth/principal.service';
import {AuthentificationService} from '@app/security/authentification.service';
import {Injectable, Injector} from '@angular/core';

@Injectable()
export class NotificationInterceptor implements HttpInterceptor {

    constructor(
        private injector: Injector,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap({
                next: (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        const arr = event.headers.keys();
                        let alert = null;
                        let alertParams = null;
                        arr.forEach(entry => {
                            if (entry.toLowerCase().endsWith('app-alert')) {
                                alert = event.headers.get(entry);
                            } else if (entry.toLowerCase().endsWith('app-params')) {
                                alertParams = event.headers.get(entry);
                            }
                        });
                        if (alert) {
                            if (typeof alert === 'string') {
                                if (this.injector.get(AlertService)) {
                                    this.injector.get(AlertService).success(alert, {param: alertParams}, undefined);
                                }
                            }
                        }
                    }
                },
                error: (err: HttpErrorResponse) => {
                    if (err['status'] === 403 || err['status'] === 401 && !(err['url'] || '').includes('/v1/deconnexion')) {
                        if (this.injector.get(Principal).isAuthenticated()) {
                            this.injector.get(AuthentificationService).logoutByClick();
                        }
                    }
                },
            }),
        );
    }
}
