import {Injectable} from '@angular/core';

@Injectable()
export class Globals {


    /**
     * Détermine si le service demandePermisVisite prévenu est activé.
     */
    serviceDemandePermisVisitePrevenuEnabled = false;

    getServiceDemandePermisVisitePrevenuEnabled(): boolean {
        return this.serviceDemandePermisVisitePrevenuEnabled;
    }
}
