import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ConfigProvider} from '../blocks/config/config.provider';
import {Rubrique} from './faq/model/rubrique.model';
import {Etablissement} from './models/etablissement.model';
import {EtablissementLight} from './models/etablissement-light.model';
import {Observable} from 'rxjs/index';
import {ReferentielEtablissement} from '@pv/models/ReferentielEtablissement.model';

@Injectable({providedIn: 'root'})
export class CommunicationService {

    constructor(private http: HttpClient, private configProvider: ConfigProvider) {
    }

    getFaq() {
        const params = new HttpParams()
            .set('sort', 'id,' + 'ASC');
        const headers = new HttpHeaders();
        const url = this.configProvider.config.apiUrl + 'nedmascommunicationfamille/api/v1/rubriques-faqs/liste';
        return this.http.get<Rubrique[]>(url, {headers, params});
    }

    /**
     * Méthode qui récupère une liste d'établissement à partir d'un input
     * @param term: string
     *
     */
    rechercherEtablissement(term: string): Observable<EtablissementLight[]> {
        const url = this.configProvider.config.apiUrl + 'nedmasreferentiel/api/v1/etablissement/recherche-etablissement-by-libelle-long';
        const params = new HttpParams().set('libelleLong', term);
        return this.http.get<EtablissementLight[]>(url, {params});
    }


    /**
     * Méthode qui récupère une liste d'établissement à partir d'un input
     * @param term: string
     *
     */
    rechercherEtablissementFAQ(term: string): Observable<EtablissementLight[]> {
        const url = this.configProvider.config.apiUrl + 'nedmascommunicationfamille/api/v1/rubriques-description-etablissements/recherche';
        const params = new HttpParams().set('nomRecherche', term);
        return this.http.get<EtablissementLight[]>(url, {params});
    }

    /**
     * Méthode qui récupère un établissement à partir de son code etablissement
     */
    getEtablissement(codeEtablissement: string) {
        const url = this.configProvider.config.apiUrl + 'nedmasreferentiel/api/v1/etablissement/get-etablissement-by-code-etablissement/' + codeEtablissement;
        return this.http.get<ReferentielEtablissement>(url);
    }

    /**
     * Méthode qui récupère un établissement à partir de son id
     */
    getEtablissementFAQ(id: string) {
        const url = this.configProvider.config.apiUrl + 'nedmascommunicationfamille/api/v1/rubriques-description-etablissements/code-etablissement/' + id;
        return this.http.get<Etablissement>(url);
    }

}
